import React from 'react';
import { makeStyles } from '@mui/styles';
import KenTextLabel from '../KenTextLabel';
import KenError from '../KenError';
import PhoneInput from 'react-phone-input-2';
import { Box, Divider, Typography } from '@mui/material';
import KenIcon from '../KenIcon';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '../../Assets/Icons/error.png';

const useStyles = makeStyles((theme) => ({
  inputFieldLabel: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '100%',
    textTransform: 'capitalize',
    color: '#505F79',
  },
  phoneContainer: {
    display: 'flex',
    '& .phone-input': {
      fontFamily: 'Poppins,sans-serif',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '100%',
      color: '#505F79',
      padding: '16px',
      order: 2,
      background: '#F3F5F7',
      border: 0,
      height: '48px',
      //   57
      borderRadius: '4px',
      marginLeft: '11px',
      '&::placeholder': {
        fontSize: '16px',
        lineHeight: '100%',
        color: '#A8AFBC',
        fontFamily: 'Poppins,sans-serif',
      },
    },
    '& .phone-dropdown': {
      position: 'relative',
      order: 1,
      border: 0,
      background: '#F3F5F7',
      '&.open': {
        background: '#F3F5F7',
        '& .selected-flag': {
          background: '#F3F5F7',
        },
      },
      '& .selected-flag': {
        width: '50px',
        padding: '10px',
        '&:hover': {
          background: '#F3F5F7',
        },
      },
    },
  },
}));

export default function KenPhoneInput(props) {
  const { error, success, ui, hideLabel = false } = props;
  const classes = useStyles();

  return (
    <>
      {!hideLabel && <KenTextLabel label="Phone Number" labelClassName={`${classes.inputFieldLabel} ${props.inputFieldLabelClass ? props.inputFieldLabelClass : ''}`} required={true} />}
      <Box display="flex" alignItems="flex-start">
        <PhoneInput
          {...props}
          containerClass={`${classes.phoneContainer} ${props.phoneContainerClass ? props.phoneContainerClass : ''}`}
          inputClass={`${classes.inputFieldLabel} phone-input`}
          buttonClass="phone-dropdown"
          country={'in'}
          preferredCountries={['in']}
          placeholder="Enter Number"
          onChange={(phone) => {
            if (typeof props.onChange === 'function') {
              if (phone.includes('+')) {
                props.onChange(phone);
              } else {
                props.onChange(`+${phone}`);
              }
            }
          }}
        />
        {error && (
          <Box width="16px" height="16px" margin="19px 8px">
            <KenIcon iconType="img" icon={ErrorIcon} variant="extraSmall"></KenIcon>
          </Box>
        )}
      </Box>
      {props?.error && <KenError message={props?.error} single />}

      {success && (
        <Box display="flex" alignItems="flex-start" pt={1}>
          <Typography component="span">{success.icon || <DoneIcon fontSize="small" style={{ color: '#158467' }} />}</Typography>
          <Typography component="span" className={classes.successMessage} pl={1}>
            {success.message}
          </Typography>
        </Box>
      )}
      {ui && ui?.divider && (
        <Box mt={2} mb={2}>
          <Divider />
        </Box>
      )}
    </>
  );
}
