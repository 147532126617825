import KenApplication from '../../Global_Components/KenApplication';
import { ApplicationContextProvider } from '../../Helpers/Contexts/applicationContext';

function ApplicationFormNew() {
  return (
    <ApplicationContextProvider>
      <KenApplication />
    </ApplicationContextProvider>
  );
}

export default ApplicationFormNew;
