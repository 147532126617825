import { Box, Checkbox, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useRef } from 'react';
import ReactToPrint from 'react-to-print/lib';
import { FormContext } from '..';
import Loader from '../../../Components/Loaders';
import { getFieldValue, getNestedData } from '../../../Helpers/applicationHelper';
import { TABLET_BREAKPOINT } from '../../../Helpers/constants';
import PrintIcon from '@mui/icons-material/Print';
import SummaryToPrint from './summaryToPrint';
const parse = require('html-react-parser');

const useStyles = makeStyles((theme) => ({
  formContainer: {
    height: 'calc(100% - 16px)',
    overflow: 'auto',
  },
  stepLabel: {
    color: 'black',
    fontWeight: 600,
  },
  sectionLabel: {
    fontWeight: 500,
  },
  sectionSubLabel: {
    color: '#7A869A',
    fontWeight: 400,
  },
  footerContainer: {
    position: 'absolute',
    bottom: '0px',
    padding: '16px',
    paddingRight: '223px',
    minHeight: 76,
    // height: "100px",
    left: '0px',
    right: '0px',
    overflow: 'hidden',
    background: '#FFFFFF',
    boxShadow: '10px 0px 20px rgba(0, 0, 0, 0.04), 2px 0px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    // backgroundColor: "blue",
  },
  container: {
    position: 'absolute',
    top: '270px',
    bottom: '0px',
    left: '0px',
    right: '0px',
  },
  paymentContainer: {
    padding: '16px 223px',
  },
  fieldNameValue: {
    wordBreak: 'break-word',
    fontWeight: 400,
  },
  fieldName: {
    color: '#000000',
    fontSize: '14px !important',
  },
  fieldValue: {
    color: '#061938',
    fontSize: '14px !important',
  },
  fieldContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      flexDirection: 'column',
      'align-items': 'flex-start',
      'justify-content': 'flex-start',
      'max-width': '100%',
      display: 'flex',
      width: '100%',
      'padding-top': '0 !important',
    },
  },
  fieldKeyContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '40%',
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      'line-height': '150%',
      'text-transform': 'capitalize',
      color: '#7A869A',
    },
  },
  fieldValueContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '60%',
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      'line-height': '150%',
      color: '#061938',
    },
    [theme.breakpoints.down('md')]: {
      width: '95%',
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      'line-height': '150%',
      color: '#061938',
    },
  },
  divider: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: '5px auto 16px 0',
    },
  },
  dividerContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: '5px',
    },
  },
  dividerWrapper: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: '0px',
      paddingTop: '0 !important',
    },
  },
}));

const KenSummary = React.forwardRef((props, ref) => {
  const { formJson, getValue, formData, declaration, setDeclaration } = useContext(FormContext);
  const { data } = props;
  const classes = useStyles();

  return (
    <>
      {data === undefined && <Loader />}
      {data !== undefined && formJson && (
        <div ref={ref}>
          <Box p={2}>
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <Typography
                  align="left"
                  //   marginBottom={theme.spacing(2)}
                  className={classes.stepLabel}
                  fontSize="26px"
                >
                  Summary
                </Typography>
              </Grid>
            </Grid>

            <Box pt={1}>
              {JSON?.parse(formJson)?.length > 0 &&
                JSON?.parse(formJson)?.map((step) => {
                  return (
                    <>
                      <Grid container spacing={2}>
                        {step?.sections?.map((section) => {
                          return (
                            <>
                              {section?.sectionVisible === true && (
                                <>
                                  <Grid item xs={12}>
                                    <Box>
                                      {section?.sectionName && (
                                        <Typography align="left" className={classes.sectionLabel} fontSize="20px">
                                          {section?.sectionName}
                                        </Typography>
                                      )}
                                      {section?.subSectionName && (
                                        <Typography align="left" className={classes.sectionSubLabel} variant="body2">
                                          {section?.subSectionName}
                                        </Typography>
                                      )}
                                    </Box>
                                  </Grid>
                                  {section?.fields?.map((field) => {
                                    return field?.metaData?.addMultiple === true ? (
                                      <Grid item container xs={12} md={12} spacing={3}>
                                        <>
                                          {getNestedData(section?.apiKeyId, section?.fields, data)?.map((nestedField, nestedFieldIndex) => {
                                            return (
                                              nestedField &&
                                              Object.keys(nestedField).map((keyName, keyIndex) => {
                                                if (nestedField[keyName] !== null && keyName !== 'undefined')
                                                  return (
                                                    <>
                                                      <Grid item container xs={12} md={6} spacing={1} className={classes.fieldContainer}>
                                                        <Grid item xs={12} sm={12} md={6} textAlign="left" className={classes.fieldKeyContainer}>
                                                          <Typography className={`${classes.fieldName} ${classes.fieldNameValue}`} variant="h5">
                                                            {keyName}
                                                          </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} textAlign="left" className={classes.fieldValueContainer}>
                                                          <Typography className={`${classes.fieldValue} ${classes.fieldNameValue}`} variant="h5">
                                                            {nestedField[keyName]}
                                                          </Typography>
                                                        </Grid>
                                                      </Grid>
                                                      {Object.keys(nestedField)?.length - 1 === keyIndex &&
                                                        getNestedData(section?.apiKeyId, section?.fields, data)?.length - 1 !== nestedFieldIndex && (
                                                          <Grid item xs={12} className={classes.dividerContainer}>
                                                            <Divider className={classes.divider} />
                                                          </Grid>
                                                        )}
                                                    </>
                                                  );
                                              })
                                            );
                                          })}
                                        </>
                                      </Grid>
                                    ) : field?.fieldId && field?.fields ? (
                                      <>
                                        {field?.fields.map((elem) => {
                                          return (
                                            <>
                                              {getFieldValue(elem.name, data) && (
                                                <Grid item container xs={12} md={6} spacing={2} className={classes.fieldContainer}>
                                                  <Grid item xs={12} sm={12} md={6} textAlign="left" className={classes.fieldKeyContainer}>
                                                    <Typography className={`${classes.fieldName} ${classes.fieldNameValue}`} variant="h5">
                                                      {elem?.label}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={12} md={6} textAlign="left" className={classes.fieldValueContainer}>
                                                    <Typography className={`${classes.fieldValue} ${classes.fieldNameValue}`} variant="h5">
                                                      {elem && getFieldValue(elem?.name, data)}
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              )}
                                              {field?.ui?.divider && (
                                                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.dividerContainer}>
                                                  <Divider className={classes.divider} />
                                                </Grid>
                                              )}
                                            </>
                                          );
                                        })}
                                      </>
                                    ) : field?.name ? (
                                      <>
                                        {getFieldValue(field.name, data) && (
                                          <Grid item container xs={12} md={6} spacing={2} className={classes.fieldContainer}>
                                            <Grid item xs={12} sm={12} md={6} textAlign="left" className={classes.fieldKeyContainer}>
                                              <Typography className={`${classes.fieldName} ${classes.fieldNameValue}`} variant="h5">
                                                {field?.label}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} textAlign="left" className={classes.fieldValueContainer}>
                                              <Typography className={`${classes.fieldValue} ${classes.fieldNameValue}`} variant="h5">
                                                {field && getFieldValue(field?.name, data)}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        )}
                                        {field?.ui?.divider && (
                                          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.dividerContainer}>
                                            <Divider className={classes.divider} />
                                          </Grid>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {field?.options.map((el) => {
                                          return (
                                            <>
                                              {getFieldValue(`${field.name}[${el.name}]`, data) && (
                                                <Grid item container xs={12} md={6} spacing={2} className={classes.fieldContainer}>
                                                  <Grid item xs={12} sm={12} md={6} textAlign="left" className={classes.fieldKeyContainer}>
                                                    <Typography className={`${classes.fieldName} ${classes.fieldNameValue}`} variant="h5">
                                                      {el?.label}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={12} md={6} textAlign="left" className={classes.fieldValueContainer}>
                                                    <Typography className={`${classes.fieldValue} ${classes.fieldNameValue}`} variant="h5">
                                                      {el && getFieldValue(`${field.name}[${el.name}]`, data)}
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              )}
                                              {field?.ui?.divider && (
                                                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.dividerContainer}>
                                                  <Divider className={classes.divider} />
                                                </Grid>
                                              )}
                                            </>
                                          );
                                        })}
                                      </>
                                    );
                                  })}
                                  {section?.fieldArray === true &&
                                    getNestedData(section?.apiKeyId, section?.fields, data)?.map((nestedField) => {
                                      return (
                                        nestedField &&
                                        Object.keys(nestedField).map((keyName) => {
                                          if (nestedField[keyName] !== null && keyName !== 'undefined')
                                            return (
                                              <>
                                                <Grid item container xs={12} md={6} spacing={1} className={classes.fieldContainer}>
                                                  <Grid item xs={12} sm={12} md={6} textAlign="left" className={classes.fieldKeyContainer}>
                                                    <Typography className={`${classes.fieldName} ${classes.fieldNameValue}`} variant="h5">
                                                      {keyName}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={12} md={6} textAlign="left" className={classes.fieldValueContainer}>
                                                    <Typography className={`${classes.fieldValue} ${classes.fieldNameValue}`} variant="h5">
                                                      {nestedField[keyName]}
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              </>
                                            );
                                        })
                                      );
                                    })}
                                  <Grid item xs={12} className={classes.dividerWrapper}>
                                    <Box mt={3} mb={3} className={classes.dividerContainer}>
                                      <Divider className={classes.divider} />
                                    </Box>
                                  </Grid>
                                </>
                              )}
                            </>
                          );
                        })}
                      </Grid>
                    </>
                  );
                })}
              {formData?.formDeclaration !== null && data?.application?.Application_Status__c === 'Application Incomplete' && (
                <>
                  <Typography align="left" className={classes.sectionLabel} fontSize="20px" mt={5}>
                    Declaration
                  </Typography>
                  <Grid container alignItems={'center'}>
                    <Grid item>
                      <Checkbox
                        value={declaration}
                        onChange={(e) => {
                          setDeclaration(e.target.checked);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography>I hereby, declare that</Typography>
                    </Grid>
                  </Grid>
                  <Box sx={{ textAlign: 'justify' }}>{formData?.formDeclaration && parse(formData?.formDeclaration)}</Box>
                </>
              )}
            </Box>
          </Box>
        </div>
      )}
    </>
  );
});

const KenSummaryStep = (props) => {
  const componentRef = useRef();

  return (
    <div>
      <ReactToPrint
        pageStyle="@size: 11.0in 8.5in; !important;"
        // pageStyle="@page { size: 2.5in 4in }"

        trigger={() => (
          <Grid container justifyContent="space-between">
            <Grid item></Grid>
            <Grid item alignItems="right" mb={-5}>
              <PrintIcon />
            </Grid>
          </Grid>
        )}
        content={() => componentRef.current}
        removeAfterPrint={true}
        copyStyles={true}
      />
      <KenSummary {...props} />
      <div style={{ display: 'none' }}>
        <SummaryToPrint ref={componentRef} {...props} />
      </div>
    </div>
  );
};

export default KenSummaryStep;
