import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const useStyles = makeStyles({
  root: {
    width: '100%',
    '&>:nth-child(1)': {
      backgroundColor: '#F3F5F7',
      // padding: 0,
      // height: '40px',
    },
  },
  rootCircular: {
    width: '100%',
    '&>:nth-child(1)': {
      // backgroundColor: '#F3F5F7',

      padding: 0,
    },
  },
  input: {
    borderRadius: '4px',
    padding: '16px',
    backgroundColor: '#F3F5F7',
    color: '#505F79',
    textAlign: 'left',
    '&:focus': {
      backgroundColor: '#FFFFFF',
    },
  },
  error: {
    backgroundColor: '#FFFFFF',
  },
  helperText: {
    marginLeft: 0,
    fontSize: '12px',
    color: '#FC2C32',
  },
});

const StyledTextField = styled(TextField)(({ error, border }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#F3F5F7',
      border: border,
    },
    '&:hover fieldset': {
      borderColor: error ? '#FC2C32' : 'grey',
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? '#FC2C32' : '#092682',
    },
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#FC2C32',
  },
  '& .MuiInputBase-root-MuiOutlinedInput-root.Mui-error.MuiOutlinedInput-notchedOutline':
    {
      borderColor: '#FC2C32',
    },
  '& .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root': {
    backgroundColor: '#F3F5F7',
  },
}));

const StyledTextFieldCircular = styled(TextField)(({ error, border }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#F3F5F7',
      // border: border,
      // borderRadius: 48,
    },
    '&:hover fieldset': {
      borderColor: error ? '#FC2C32' : 'grey',
      // borderRadius: 48,
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? '#FC2C32' : '#092682',
    },
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#FC2C32',
  },
  '& .MuiInputBase-root-MuiOutlinedInput-root.Mui-error.MuiOutlinedInput-notchedOutline':
    {
      borderColor: '#FC2C32',
    },
  '& .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root': {
    backgroundColor: '#F3F5F7',
  },
}));

export default function KenTextField(props) {
  const {
    FormHelperTextProps,
    InputLabelProps,
    inputProps,
    InputProps,
    className,
    error,
    errorLabel,
    children,
    disableKeyDown,
    minDate,
    maxDate,
    name,
    value,
    remaining = false,
    maxCharacters,
    inputType,
    dateType = 'year',
    withValue,
    typeOfInput,
    handleMouseDownPassword,
    showPassword,
    multiple = false,
  } = props;

  const classes = useStyles();

  const renderInput = (flag) => {
    switch (flag) {
      case 'circular':
        return (
          <StyledTextFieldCircular
            className={`${className}`}
            classes={{ root: classes.rootCircular }}
            InputLabelProps={{ shrink: false, ...InputLabelProps }}
            inputProps={{
              className: error
                ? `${classes.input} ${classes.error}`
                : classes.input,
              ...inputProps,
            }}
            InputProps={{
              components: 'p',
              endAdornment:
                typeOfInput === 'password' ? (
                  <InputAdornment
                    position="start"
                    classes={{ positionStart: '0px' }}
                  >
                    <IconButton
                      // onClick={handleMouseDownPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ) : null,
              inputProps: {
                min:
                  minDate &&
                  minDate !== null &&
                  (minDate === true
                    ? moment().format('YYYY-MM-DD')
                    : withValue
                    ? moment(value)
                        .subtract(Number(minDate), dateType)
                        .format('YYYY-MM-DD')
                    : moment()
                        .subtract(Number(minDate), dateType)
                        .format('YYYY-MM-DD')),
                max:
                  maxDate &&
                  maxDate !== null &&
                  (maxDate === true
                    ? moment().format('YYYY-MM-DD')
                    : withValue
                    ? moment(value)
                        .add(Number(maxDate), dateType)
                        .format('YYYY-MM-DD')
                    : moment()
                        .add(Number(maxDate), dateType)
                        .format('YYYY-MM-DD')),
                'data-testid': `test-${name}`,
              },
              ...InputProps,
            }}
            FormHelperTextProps={{
              className: classes.helperText,
              ...FormHelperTextProps,
            }}
            placeholder={`Enter ${name}`}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: '300px',
                    marginTop: '8px',
                    boxSizing: 'border-box',
                    borderTop: '8px solid #ffffff',
                    borderBottom: '8px solid #ffffff',
                  },
                },
              },
              multiple: multiple,
            }}
            {...props}
            multiSelect={true}
            onKeyDown={(e) => {
              disableKeyDown && e.preventDefault();
            }}
            label=""
            error={errorLabel ? errorLabel : error}
            helperText={
              errorLabel ? null : error ? (
                error
              ) : (
                <>
                  {remaining === true && (
                    <p style={{ color: '#505F79', marginTop: '-2px' }}>
                      Remaining Characters:{' '}
                      {maxCharacters - (value?.length || 0)}
                    </p>
                  )}
                </>
              )
            }
          >
            {children}
          </StyledTextFieldCircular>
        );

      default:
        return (
          <StyledTextField
            className={`${className}`}
            classes={{ root: classes.root }}
            InputLabelProps={{ shrink: false, ...InputLabelProps }}
            inputProps={{
              className: error
                ? `${classes.input} ${classes.error}`
                : classes.input,
              ...inputProps,
            }}
            InputProps={{
              components: 'p',
              inputProps: {
                min:
                  minDate &&
                  minDate !== null &&
                  (minDate === true
                    ? moment().format('YYYY-MM-DD')
                    : withValue
                    ? moment(value)
                        .subtract(Number(minDate), dateType)
                        .format('YYYY-MM-DD')
                    : moment()
                        .subtract(Number(minDate), dateType)
                        .format('YYYY-MM-DD')),
                max:
                  maxDate &&
                  maxDate !== null &&
                  (maxDate === true
                    ? moment().format('YYYY-MM-DD')
                    : withValue
                    ? moment(value)
                        .add(Number(maxDate), dateType)
                        .format('YYYY-MM-DD')
                    : moment()
                        .add(Number(maxDate), dateType)
                        .format('YYYY-MM-DD')),
                'data-testid': `test-${name}`,
              },
              ...InputProps,
            }}
            FormHelperTextProps={{
              className: classes.helperText,
              ...FormHelperTextProps,
            }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: '300px',
                    marginTop: '8px',
                    boxSizing: 'border-box',
                    borderTop: '8px solid #ffffff',
                    borderBottom: '8px solid #ffffff',
                  },
                },
              },
              multiple: multiple,
            }}
            {...props}
            multiSelect={true}
            onKeyDown={(e) => {
              disableKeyDown && e.preventDefault();
            }}
            label=""
            error={errorLabel ? errorLabel : error}
            helperText={
              errorLabel ? null : error ? (
                error
              ) : (
                <>
                  {remaining === true && (
                    <p style={{ color: '#505F79', marginTop: '-2px' }}>
                      Remaining Characters:{' '}
                      {maxCharacters - (value?.length || 0)}
                    </p>
                  )}
                </>
              )
            }
          >
            {children}
          </StyledTextField>
        );
    }
  };

  return <>{renderInput(inputType)}</>;
}
