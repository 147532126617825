import { Dispatch, createContext, useEffect, useReducer } from "react";
import { AuthAction, AuthState } from "../../interfaces/Contexts";
import { ReactChildren } from "../../interfaces/ICommon";
import { KEY_ACCESS_TOKEN, KEY_REFRESH_TOKEN } from "../constants";

export const initialState: AuthState = {
    isAuthenticated: false,
    user: null,
    search: null,
};

const reducer = (state: AuthState, action: AuthAction): AuthState => {
    switch (action.type) {
      case "LOGIN":
        const userPayload = action.payload || { user: null };
        sessionStorage.setItem(KEY_REFRESH_TOKEN, userPayload.user?.refreshToken?.token ?? "");
        sessionStorage.setItem(KEY_ACCESS_TOKEN, userPayload.user?.accessToken?.token ?? "");
        sessionStorage.setItem("user", JSON.stringify(userPayload.user));
        return {
          ...state,
          isAuthenticated: userPayload.user && userPayload.user === null ? false : true,
          user: userPayload.user || null,
        };
      case "LOGOUT":
        sessionStorage.clear();
        return {
          ...state,
          isAuthenticated: false,
          user: null,
          search: null,
        };
      default:
        return state;
    }
  };

export const AuthContext = createContext<{
    state: AuthState;
    dispatch: Dispatch<AuthAction>;
}>({ state: initialState, dispatch: () => {} });
  
export const AuthContextProvider: React.FunctionComponent<ReactChildren> = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const user = JSON?.parse(sessionStorage?.getItem("user") ?? "null");
    if (user) {
      dispatch({
        type: "LOGIN",
        payload: {
          user,
        },
      });
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};