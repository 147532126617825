import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocation, useNavigate } from 'react-router-dom';
// import FormHeader from '../../Containers/ApplicationForm/Components/FormHeader';
// import { AuthContext } from '../../Helpers/';
// import { UploadDocumentContextProvider } from '../../Context/uploadDocumentContext';
import { getVisibleSteps } from '../../Helpers/applicationHelper';
import CustomizedSteppers from '../KenApplicationForm/Components/KenStepperHeader';
import Stepper from './Stepper';
import { useApplicationContext } from '../../Helpers/Contexts/applicationContext';

export default function KenApplication(props) {
  const navigate = useNavigate();
  const formRef = useRef();
  const { t } = useTranslation();

  const urlSearch = useLocation().search;

  // const {
  //   state: { user },
  // } = useContext(AuthContext);

  const { applicationState, applicationDispatch } = useApplicationContext();

  const { formId, applicationId, applicationData, form, steps = [], activeStep, showHeader } = applicationState;

  const [isDataFetched, setDataFetched] = useState(false);
  const [dialog, setDialog] = useState();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  
  const handleBackClick = () => {
    if (activeStep > 0 && steps.length > 2) {
      setShowConfirmDialog(true);
    } else {
      navigate(-1);
    }
  };
  const location = useLocation();
  useEffect(() => {
    if (applicationData) {
      setDataFetched(true);
    }
  }, [applicationData]);

  const { containerStyle } = props;

  const { stepperHeader = false, totalDoc } = location.state || props;

  //update context state
  const updateState = (payload) => {
    applicationDispatch({ type: 'update', payload });
  };

  console.log(props, location?.state,"lod");

  // const handleConfirmDialogDecision = async (decision) => {
  //   if (decision === 'yes') {
  //     try {
  //       const data = applicationState?.extraData?.BatchIds?.map((el) => {
  //         return postModeOfAssessment({
  //           Id: el.Id,
  //           Status__c: 'Discard',
  //         });
  //       });
  //       let result = await Promise.all(data);
  //       if (result.every((el) => el.success === true)) {
  //         navigate(-1);
  //       }
  //     } catch (error) {
  //       console.error('API error:', error);
  //     }
  //   }
  //   setShowConfirmDialog(false);
  // };

  const getLast30Years = () => {
    var currentYear = new Date().getFullYear();
    var last30Years = [];

    for (var i = currentYear; i >= currentYear - 50; i--) {
      last30Years.push({ label: i, value: i });
    }
    return last30Years;
  };

  // update applicant id to context
  // useEffect(() => {
  //   updateState({ applicantId: user?.applicantId });
  // }, [user]);

  useEffect(() => {
    //Extract only steps to be used
    const steps = getVisibleSteps(location?.state?.form || props?.form);
    // if (location?.state?.data?.Account?.Partner_Status__c === 'Registered') {
    //   setDialog(true);
    // }

    // if (Object.keys(props).length > 0) {
    //   updateState({
    //     applicationData: {
    //       ...props?.data,
    //     },
    //     steps,
    //     totalSteps: steps?.length,
    //     ...(applicationId
    //       ? {}
    //       : {
    //         activeStep: props?.activeStep ? props?.activeStep : 0,
    //       }),
    //     formOptions: {
    //       scheme: props?.scheme || [],
    //       year: getLast30Years(),
    //       ...props?.formOptions,
    //     },
    //     hideFooter: props?.hideFooter ? props?.hideFooter : false,
    //     editForm: props?.editForm,
    //     hideEdit: props?.hideEdit ? props?.hideEdit : false,
    //     footerFixed: props?.footerFixed,
    //     totalDoc: props?.totalDoc || 0,
    //     extraData: props?.extraData || {},
    //   });
    // } else {
      updateState({
        applicationData: {
          ...location?.state?.data,
        },
        steps,
        totalSteps: steps.length,
        ...(applicationId
          ? {}
          : {
            activeStep: location?.state?.activeStep ? location?.state?.activeStep : 0,
          }),
        formOptions: {
          scheme: location?.state?.scheme || [],
          year: getLast30Years(),
        },
        hideFooter: location?.state?.hideFooter ? location?.state?.hideFooter : false,
        editForm: location?.state?.editForm,
        hideEdit: location?.state?.hideEdit ? location?.state?.hideEdit : false,
        footerFixed: location?.state?.footerFixed ? props?.footerFixed : true,
        totalDoc: totalDoc || 0,
        extraData: location?.state?.extraData || {},
      });
    // }
  }, [location]);

  console.log(applicationState, "location")

  useEffect(() => {
    // console.log('active step, steps change: ');
  }, [activeStep, steps]);

  //onSave
  // no validations >>
  // get values from context and call api

  // TODO: input event to be replaced with formValues

  return (
    <div>
      {/* <FormHeader hideBack={location?.state?.hideBack ? location?.state?.hideBack : false} hideHeader={props?.hideHeader || false} onBackClick={handleBackClick} /> */}
      {isDataFetched && (
        <>
          <div style={containerStyle === false ? { marginTop: '0px' } : stepperHeader === true ? { marginTop: '65px' } : { marginTop: '0px' }}>
            {stepperHeader && <CustomizedSteppers activeStep={activeStep} steps={steps} />}
            {/* <UploadDocumentContextProvider> */}
              <Stepper step={steps[activeStep]} formRef={formRef} goNextStep={props?.goNextStep} />
            {/* </UploadDocumentContextProvider> */}
          </div>
        </>
      )}
      {/* <KenDialog
        open={dialog}
        handleClose={() => {
          setDialog(false);
        }}
        dialogActions={true}
        positiveButtonClick={() => {
          setDialog(false);
        }}
        positiveButtonText={'OK'}
        positiveButtonProps={{
          style: {
            color: '#fff',
            backgroundColor: '#092682',
            borderRadius: '4px',
            '&:hover': {
              cursor: 'pointer',
              background: '#DFE8FF',
              color: '#505F79',
            },
            padding: '8px 12px 8px 12px',
            fontSize: 14,
            marginBottom: 16,
          },
        }}
        hideNegativeButton={true}
      >
        <Typography>Complete the registration form by filling and submitting all details in the onboarding form.</Typography>
      </KenDialog>
      <KenDialog
        open={showConfirmDialog}
        maxWidth={'xs'}
        handleClose={() => setShowConfirmDialog(false)}
        dialogActions={true}
        positiveButtonClick={() => handleConfirmDialogDecision('yes')}
        positiveButtonText={'Yes'}
        title={<Typography style={{ fontSize: '16px', fontWeight: 600 }}>Warning</Typography>}
        negativeButtonClick={() => handleConfirmDialogDecision('no')}
        negativeButtonText={'No'}
        positiveButtonProps={{
          style: {
            backgroundColor: '#092682',
            color: '#fff',
            textTransform: 'capitalize',
          },
        }}
        negativeButtonProps={{
          style: {
            textTransform: 'capitalize',
          },
        }}
      >
        <Typography style={{ fontSize: '14px', fontWeight: 500 }}>Are you sure you want to discard Batch Request?</Typography>
      </KenDialog> */}
    </div>
  );
}
