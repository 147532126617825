export const BACKEND_URL = 'https://iimu-prod-api.kennovate.com/'; //"http://localhost:4003/";

export const SALESFORCE_URL = 'https://ken42--kennovate2.sandbox.my.salesforce.com/';

export const TABLET_BREAKPOINT = 'tablet';

export const KEY_ACCESS_TOKEN = 'access_token';
export const KEY_REFRESH_TOKEN = 'refresh_token';

export const REGEX = {
  NAME: /^[a-zA-Z ]*$/,
  MOBILE: /^(\+\d{1,3}[- ]?)?\d{10}$/,
  PINCODE: /^[0-9]{4,7}$/,
  NUMBER: /^[0-9]/,
  YEAR: /^[0-9]{4,4}$/,
  ONLYNUMBER: /^[0-9]*$/,
  UPPERCASE: /[A-Z]/,
  LOWERCASE: /[a-z]/,
  DIGIT: /\d/,
  SPECIAL: /[$&+,:;=?@#|'<>.^*()%!-]/,
  NUMBERWITHDECIMAL: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
  DIGITS_ONLY_UPTO_100: /^\-?(([0-9]\d?|0\d{1,2})((\.)\d{0,2})?|100((\.)0{1,2})?)$/,
  DIGITS_ONLY_UPTO_10: /^\-?((0\d|\d)?((\.)\d{0,2})?|10((\.)0{1,2})?)$/,
  DIGITS_ONLY_UPTO_4: /^\-?((0[0-3]|[0-3])?((\.)\d{0,2})?|(4|04)((\.)0{1,2})?)$/,
  PERCENTAGE: /^100$/,
  CGPA: /^10$/,
  GRADE: /^[a-fA-FOoPp]$/,
  MIXED: /^[a-zA-Z0-9-./, ]*$/,
  GST: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  PARENT_DOC: /^aadhaarParent/,
  PHONE_NUMBER: /^[0-9]*$/,
  PASSWORD: '(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,}',
  AADHAAR: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
  WEBSITE: /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/,
  EMAIL: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
};
