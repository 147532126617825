import React from "react";
import createTheme from "@mui/system/createTheme";
import ThemeProvider from "@mui/styles/ThemeProvider";
import propType from "prop-types";
import { Theme } from "../interfaces/IStepperForm";

const Themes: React.FC<Theme> = ({ children }) => {
  const fontFamily = "Red Hat Text";
  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: [fontFamily, "sans-serif"].join(","),
        textTransform: "none",
      },
    },
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

Themes.propTypes = {
  children: propType.node.isRequired,
};

export default Themes;
