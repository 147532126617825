import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TABLET_BREAKPOINT } from '../../../../Helpers/constants';
import KenButton from '../../../KenButton';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px',
    background: '#FFFFFF',
    boxShadow: '10px 0px 20px rgba(0, 0, 0, 0.04), 2px 0px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    paddingRight: '10vw',
    paddingLeft: '10vw',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    minHeight: 76,
    [theme.breakpoints.down('xs')]: {
      margin: 12,
    },
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: '0px 16px',
      'min-height': '60px',
      display: 'flex',
      'justify-content': 'center',
      'align-items': 'center',
      position: 'fixed',
      bottom: '0px',
      width: '100%',
    },
  },
  previousBtn: {
    gap: '10px',
    width: '111px',
    'border-radius': '4px',
    border: '0px !important',
    'font-style': 'normal',
    'font-weight': '500 !important',
    'font-size': '18px !important',
    'line-height': '100% !important',
    display: 'flex',
    'text-align': 'center',
    color: '#092682 !important',
    backgroundColor: 'transparent !important',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      marginLeft: '16px',
    },
  },
  saveBtn: {
    gap: '10px',
    'border-radius': '4px',
    'font-style': 'normal',
    'font-weight': '500 !important',
    'font-size': '18px !important',
    'line-height': '100% !important',
    display: 'flex',
    'text-align': 'center',
    color: '#092682 !important',
    backgroundColor: 'transparent !important',
    padding: '8px',
    width: '160px',
    height: '48px',
    border: '1px solid #092682 !important',

    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: 80,
    },
  },
  nextBtn: {
    display: 'flex',
    padding: '8px',
    gap: '8px',
    width: '140px',
    height: '48px',
    backgroundColor: '#092682 !important',
    'border-radius': '4px',
    'font-style': 'normal',
    'font-weight': '500 !important',
    'font-size': '18px !important',
    'line-height': '100% !important',
    'text-align': 'center',

    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      marginRight: '16px',
      width: 80,
    },
  },
}));

export default function KenStepperFooter(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down(TABLET_BREAKPOINT));
  const { onNext, onPrev, onSave, isSave = true, isPrev = true, isNext = true, activeStep, totalSteps, applicationStatus = 'Application Incomplete' } = props;

  return (
    <div
      className={classes.root}
      style={{
        display: applicationStatus === 'Application Incomplete' || applicationStatus === '' ? '' : 'none',
      }}
    >
      <Grid container justifyContent={'space-between'} direction="row">
        <Grid item>
          {isPrev && (
            <KenButton
              buttonClass={classes.previousBtn}
              variant="outlined"
              startIcon={isMobileScreen ? null : <ArrowBackIcon />}
              onClick={onPrev}
              disabled={activeStep === 0 || applicationStatus === 'Application form Submitted'}
              style={{
                marginRight: 8,
                pointerEvents: 'auto',
                cursor: activeStep === 0 || applicationStatus === 'Application form Submitted' ? 'not-allowed' : 'pointer',
              }}
              data-testid="btn-prev"
            >
              {isMobileScreen ? 'Prev' : 'Previous'}
            </KenButton>
          )}
        </Grid>
        <Grid item>
          <>
            <Grid container justifyContent="flex-end">
              <Grid item>
                {/* {isSave && (
                  <KenButton buttonClass={classes.saveBtn} variant="outlined" style={{ marginRight: 8 }} onClick={onSave} data-testid="btn-save">
                    Save as draft
                  </KenButton>
                )} */}
              </Grid>
              <Grid item>
                {isNext && (
                  <KenButton
                    buttonClass={classes.nextBtn}
                    type="submit"
                    form={`form-step${activeStep}`}
                    variant="contained"
                    onClick={onNext}
                    disabled={applicationStatus === 'Application form Submitted'}
                    endIcon={isMobileScreen ? null : <ArrowForwardOutlinedIcon />}
                    data-testid="btn-next"
                  >
                    {activeStep === totalSteps - 1 ? 'Submit' : 'Next'}
                  </KenButton>
                )}
              </Grid>
            </Grid>
          </>
        </Grid>
      </Grid>
    </div>
  );
}
