import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DoneIcon from '../../../../Assets/Icons/tickInCircle.png';
// import { AuthContext } from '../../../../Context/authContext';
import KenIcon from '../../../KenIcon';
import KenLoader from '../../../KenLoader/index';

const useStyles = makeStyles((theme) => ({
  link: {
    color: '#0000EE',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default function ThankYouPage(props) {
  const {
    icon,
    title = 'Registration Successful',
    description = "Your Fee's has been successfully paid.",
    wrapperClassName,
    titleClass,
    subTitleClass,
    redirection = true,
  } = props;

  // const {
  //   state: { user },
  //   dispatch,
  // } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState();

  const [titleReceived, setTitleReceived] = useState();
  const [descriptionReceived, setDescriptionReceived] = useState();
  const [failed, setFailed] = useState(false);

  const query = new URLSearchParams(useLocation().search);
  const applicationId = query.get('applicationId');
  const coupon = query.get('couponId');
  const transactionId = query.get('transactionId');
  const remainingAmount = query.get('remaining');
  const name = query.get('name');
  const classes = useStyles();

  useEffect(() => {
    if (transactionId !== null) {
      setLoader(true);
      // getTransactionDetailsByTransactionId(transactionId)
      //   .then((res) => {
      //     if (
      //       res?.data?.status === 'Failed' &&
      //       (res?.data?.webhookStatus === 'initiated' ||
      //         res?.data?.webhookStatus === 'cancelled' ||
      //         res?.data?.webhookStatus === 'failed')
      //     ) {
      //       setFailed(true);
      //       setLoader(false);
      //     }

      //     if (res?.data?.status === 'Success' && coupon !== 'undefined') {
      //       // postAppliedCouponCode({
      //       //   couponCodeData: [
      //       //     {
      //       //       Contact: user?.applicantId,
      //       //       Application: applicationId,
      //       //       CouponCode: coupon,
      //       //     },
      //       //   ],
      //       // })
      //       //   .then((resp) => {
      //       //     console.log(resp);
      //       //   })
      //       //   .catch((err) => {
      //       //     console.log(err);
      //       //   });
      //     }
      //     if (res?.data?.status === 'Success' && Number(remainingAmount) == 0) {
      //       let obj = {};
      //       if (name.toLowerCase().includes('interview')) {
      //         obj = { interviewFeePaid: 'true' };
      //       } else if (name.toLowerCase().includes('registration')) {
      //         obj = { registrationFeePaid: 'true' };
      //       } else if (name.toLowerCase().includes('aptitude')) {
      //         obj = { spjatFeePaid: 'true' };
      //       }
      //       createUpdateApplication({
      //         applicationId: applicationId,
      //         application: {
      //           CurrentStepName: `${name} paid`,
      //           applicantId: user?.applicantId,
      //           recordId: applicationId,
      //           ...obj,
      //         },
      //       })
      //         .then((resp) => {
      //           console.log(resp);
      //         })
      //         .catch((err) => {
      //           console.log(err);
      //         });
      //     }
      //     setLoader(false);
      //   })
      //   .catch((err) => {
      //     setLoader(false);
      //     console.log(err);
      //   });
    }
  }, [transactionId]);

  useEffect(() => {
    if (location?.state?.title && location?.state?.description) {
      setTitleReceived(location?.state?.title);
      setDescriptionReceived(location?.state?.description);
    }
  }, [location?.state]);

  return (
    <Box
      textAlign="center"
      margin="auto"
      width="50%"
      marginTop="25vh"
      className={wrapperClassName}
    >
      {loader && <KenLoader />}
      <Box>
        {failed === false ? (
          <KenIcon iconType="img" icon={DoneIcon}></KenIcon>
        ) : (
          <CancelIcon sx={{ fontSize: 95, color: 'red' }} />
        )}
      </Box>

      <Box mt={3} mb={5}>
        <Typography variant="h4" fontWeight={500} className={titleClass}>
          {titleReceived || title}
        </Typography>
      </Box>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Grid item>
          <Typography
            variant="subtitle3"
            fontWeight={400}
            className={subTitleClass}
          >
            {descriptionReceived}
          </Typography>
        </Grid>
        {redirection && (
          <Grid item>
            <Typography
              variant="subtitle3"
              fontWeight={400}
              fontSize="17px"
              className={subTitleClass}
            >
              {/* Click here to redirect to{' '} */}
              {/* <span
                onClick={() => {
                  navigate(
                    location?.state?.link ? location?.state?.link : '/login'
                  );
                  if (!location?.state?.link) {
                    dispatch({
                      type: 'LOGOUT',
                      payload: {
                        user: {},
                        token: null,
                      },
                    });
                  }
                }}
                className={classes.link}
              >
                {location?.state?.redirect
                  ? location?.state?.redirect
                  : 'Login'}
              </span> */}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
