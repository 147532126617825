import _ from 'lodash';

export const getVisibleSteps = (jsonData) => {
  // const steps = JSON.parse(metaData) || JSON.parse(defaultJson);
  const steps = jsonData;
  console.log(steps, "steps");

  return steps
    ?.filter((step) => step.stepVisible)
    ?.map((step) => {
      step.sections = step.sections.filter((section) => section.sectionVisible);
      return step;
    });
  return steps;
};

export function applicationAddCurrentStep(payloadApplication, { application = {} }, activeStep, next = false) {
  let stepVal = null;
  if (parseInt(application.Current_Step__c || 0) <= activeStep) {
    stepVal = { Current_Step__c: next === true ? activeStep + 1 : activeStep };
  }
  return stepVal ? { application: { ...(payloadApplication || application), ...stepVal } } : {};
}

export function addExtrasFieldsToObject(obj, extraFields) {
  const objectVals = addObjectExtras(obj, extraFields);
  // const arrayVals = addArrayExtras(obj, extraFields);
  return objectVals;
}

function addObjectExtras(obj, extraFields) {
  return _(obj)
    .pickBy(_.isObject)
    .mapValues((e) => {
      console.log('==>: ', e, _.isArray(e), _.isObject(e));
      if (_.isArray(e)) {
        return _.map(e, (a) => addObjectExtras(_.extend({}, a, extraFields), extraFields));
      }
      if (_.isObject(e)) {
        return addObjectExtras(_.extend({}, e, extraFields), extraFields);
      }
      return _.extend({}, e, extraFields);
    })
    .assign(_.omitBy(obj, _.isObject))
    .value();
}

export const getSplitName = (combinedName, key, returnArray = false) => {
  const obj = {
    objectName: '',
    fieldName: '',
  };

  const replaceBrackets = combinedName.replace(/[\[\]']+/g, ' ')?.trim();
  const splitStr = replaceBrackets?.split(' ');

  if (Array.isArray(splitStr) && splitStr.length > 0) {
    obj.objectName = splitStr[0];
    obj.fieldName = splitStr[1];
  }

  if (key === 'objectName') return obj.objectName;
  if (key === 'fieldName') return obj.fieldName;
  if (returnArray) return splitStr;
  return obj;
};

export const getFieldValue = (fieldName, data) => {
  try {
    const keysArray = getSplitName(fieldName, '', true) || [];
    let currentData = data;

    for (const key of keysArray) {
      if (currentData && key in currentData) {
        currentData = currentData[key];
      } else {
        // Handle the case where the key does not exist in the data
        return null;
      }
    }

    return currentData;
  } catch (error) {
    console.error('Error in eval:', error);
    return null;
  }
};

export const getNestedData = (name, structure, data) => {
  const flatStructureData = structure.map((a) => {
    return { name: a?.name, label: a?.label };
  });
  let dataByName;
  if (/\d/.test(name)) {
    dataByName = getFieldValue(name);
  } else {
    dataByName = data[name] || [];
  }
  const allData = dataByName?.map((el) => {
    let obj = {};
    flatStructureData?.forEach((item) => {
      obj[item.label] = item.name.includes('semesterNumber') || item.name.includes('yearNumber') ? el[item?.name]?.replace(/\D/g, '') : el[item?.name];
    });
    return obj;
  });
  return allData;
};

export function filterDirtyFields(dirtyFields) {
  const onlyObjs = removeEmptyObjects(dirtyFields);
  // const arraysRemoved = removeEmptyArrays(dirtyFields);
  // console.log("Onlyobjs: ", arraysRemoved);

  return { ...onlyObjs };
}

export function getDirtyValues(dirtyFields, allValues) {
  return _.pick(allValues, _.keys(dirtyFields));
}

function removeEmptyObjects(obj) {
  const oo = removeFalseKeys(obj);

  return (
    _(oo)
      .pickBy(_.isObject)
      // .omitBy(_.isArray)
      .mapValues(removeEmptyObjects)
      .omitBy(_.isEmpty)
      .omitBy((o) => {
        const isValid =
          Object.keys(o).filter((key) => {
            return o[key];
          }).length === 0;

        return isValid;
      })
      .assign(_.omitBy(oo, _.isObject))
      .value()
  );
}

function removeFalseKeys(obj) {
  const t = _(obj)
    .pickBy(_.isBoolean)
    .omitBy((o) => {
      return !o;
    })
    .value();

  const t1 = _(obj).omitBy(_.isBoolean).value();
  return { ...t, ...t1 };
}
