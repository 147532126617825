import { AxiosResponse } from 'axios';
import axios from 'axios';
import { BACKEND_URL } from '../Helpers/constants';
import { IGetEventsAPIResponse, IGetEventsResponse } from '../interfaces/IDashBoard';
import { ILoginApiPayload, ILoginApiResponse, ISignUpPayload } from '../interfaces/ILogin';
import { useEffect } from 'react';
import { ICustomerPayment } from '../interfaces/IStepperForm';

/*
*
*
Axios Instance Creation
*
*
*/

const AppInstance = axios.create({
  baseURL: BACKEND_URL,
});

/*
*
*
Authentication API's
*
*
*/

// Login (POST)

export const login: (payload: ILoginApiPayload) => Promise<AxiosResponse<ILoginApiResponse>> = (payload: ILoginApiPayload) => {
  const getLoginResponse = AppInstance.post('v1/login', payload);
  return getLoginResponse;
};

// AccessToken (POST)

export const getAccessToken: (refreshToken: string) => Promise<AxiosResponse<ILoginApiResponse>> = (refreshToken: string) => {
  const payload = {
    refreshToken: refreshToken,
    portal: 'application-portal',
  };
  const getRefresh = AppInstance.post('v1/refreshToken', payload);
  return getRefresh;
};

// Logout (POST)

export const logout: (refreshToken: string) => Promise<AxiosResponse<ILoginApiResponse>> = (refreshToken: string) => {
  const payload = {
    refreshtoken: refreshToken,
  };
  const logout = AppInstance.post('v1/logout', payload);
  return logout;
};

/**
 *
 *
 *
 * New Authentication
 *
 *
 * */

export const getOtpWithoutContact = (email: string) => {
  const res = AppInstance.post(`v1/otpwithoutsaving?email=${email}`);
  return res;
};

export const verifyOtpWithoutContact = (email: string, otp: string) => {
  const res = AppInstance.get(`v1/verifyotpwithoutcontact?contact=${email}&otpEnteredEmail=${otp}`);
  return res;
};

export const getOtp = (email: string) => {
  const res = AppInstance.post(`v1/signupotp?contact=${email}`);
  return res;
};

export const verifyOtp = (email: string, otp: string) => {
  const res = AppInstance.get(`v1/verifyotp?contact=${email}&otpentered=${otp}`);
  return res;
};

export const saveContact = (payload: ISignUpPayload) => {
  const res = AppInstance.post('v1/application/startup', { applicant: payload });
  return res;
};

/*
*
*
Axios Interceptor Configuration
*
*
*/

export const ApiConfig: () => string | null = () => {
  const accessToken = localStorage.getItem('access_token');
  useEffect(() => {
    AppInstance.interceptors.request.use((req) => {
      req.headers.Authorization = `Bearer ${accessToken}`;
      return req;
    });
    AppInstance.interceptors.response.use((res) => {
      if (res.status === 401) {
        const refreshToken = localStorage.getItem('refresh_token');
        getAccessToken(refreshToken as string)
          .then((res: AxiosResponse<ILoginApiResponse>) => {
            localStorage.setItem('refresh_token', res.data.result.refreshToken);
            localStorage.setItem('access_token', res.data.result.accessToken);
          })
          .catch((err) => {
            console.error(err);
            localStorage.clear();
            window.location.href = '/';
          });
      }
      return res;
    });
  }, [accessToken]);

  return accessToken;
};

/*
*
*
Dashboard
*
*
*/

// Get Events (GET)

export const getEvents: () => Promise<AxiosResponse<IGetEventsAPIResponse<Array<IGetEventsResponse>>>> = async () => {
  const getData = await AppInstance.get('v1/application/startup/forms');
  return getData;
};

//Get Event Details (GET)

export const getEventDetails: (id: string) => Promise<AxiosResponse<IGetEventsAPIResponse<IGetEventsResponse>>> = async (id: string) => {
  const getDetails = await AppInstance.get('v1/application/startup/formsbyid', {
    params: {
      formid: id,
    },
  });
  return getDetails;
};

// GET Pending Forms (GET)

export const getPendingForms: (applicantId: string) => Promise<AxiosResponse> = async (applicantId: string) => {
  const getPendingData = await AppInstance.get('/v1/startup/application/applicant', {
    params: {
      applicantid: applicantId,
    },
  });
  return getPendingData;
};

// Get Pending Form Details

export const getPendingFormDetails: (formId: string) => Promise<AxiosResponse> = async (formId: string) => {
  const getPendingFormDetails = await AppInstance.get('v1/startup/application', {
    params: {
      applicationid: formId,
    },
  });
  return getPendingFormDetails;
};

/*
*
*
Stepper Form
*
*
*/

// Post Event Application (POST)

export const postEventApplication: (payload: any) => Promise<AxiosResponse> = async (payload: any) => {
  const postData = await AppInstance.post('v1/application/startup', payload, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  return postData;
};

// Get Attachements Array List (GET)

export const getAttachementsList: (formId: string) => Promise<AxiosResponse> = async (formId: string) => {
  const getAttachements = await AppInstance.get('v1/startup/applicationdocuments', {
    params: {
      applicationid: formId,
    },
  });
  return getAttachements;
};

// Get Documents By ID (GET)

export const getDocuments: (recordId: string) => Promise<AxiosResponse> = async (recordId: string) => {
  const getDocuments = await AppInstance.get('v1/startup/applicationdocuments/docid', {
    params: {
      docid: recordId,
    },
  });
  return getDocuments && getDocuments.data;
};

export const postInterviewSlots: (payload: any) => Promise<AxiosResponse> = async (payload: any) => {
  const result = await AppInstance.post('v1/startup/interviewSlots', payload, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  return result && result?.data;
};

export const postYesBankInitiatePayment: (payload: any) => Promise<AxiosResponse> = async (payload: any) => {
  const result = await AppInstance.post('v1/initiate/yesbank/payment', payload, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  return result && result?.data;
};

export const getInterviewSlots: (formId: string) => Promise<AxiosResponse> = async (formId: string) => {
  const result = await AppInstance.get('v1/startup/interviewSlots', {
    params: {
      formId: formId,
    },
  });
  return result && result?.data;
};

// Payment VIA Razorpay (POST)

export const postPayment: (amount: number, currency: string, event: string, customer: ICustomerPayment, formId: string, callbackUrl: string) => Promise<AxiosResponse> = async (
  amount: number,
  currency: string,
  event: string,
  customer: ICustomerPayment,
  formId: string,
  callbackUrl: string
) => {
  const contactId = localStorage.getItem('contactId');
  const getPaymentSucces = await AppInstance.post('v1/generatepaymentlink', {
    amount: amount,
    currency: currency,
    description: event,
    customer: customer,
    notify: {
      sms: true,
      email: true,
    },
    reminder_enable: true,
    notes: {
      participateid: '',
      applicationid: formId,
      contactid: contactId,
      RecordTypeId: 'Application Transaction',
      policy_name: 'Jeevan Bima',
    },
    callback_url: callbackUrl,
    callback_method: 'get',
  });
  return getPaymentSucces;
};

export const postSaveStartupQuarterEarningFile: (payload: any) => Promise<AxiosResponse> = async (payload: any) => {
  const postData = await AppInstance.post('v1/application/saveStartupQuarterEarningFile', payload, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  return postData;
};