import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { postEventApplication, postSaveStartupQuarterEarningFile } from '../../ApiServices/Api';
import { useAppContext } from '../../Helpers/Contexts/appContext';
import { useApplicationContext } from '../../Helpers/Contexts/applicationContext';
import { AuthContext } from '../../Helpers/Contexts/authContext';
import { addExtrasFieldsToObject, applicationAddCurrentStep, filterDirtyFields, getDirtyValues } from '../../Helpers/applicationHelper';
import KenStepperFooter from './Components/KenStepperFooter';
import Section from './Section';
import Loader from '../../Components/Loaders';
import { useNavigate } from 'react-router-dom';

function Form({ step: { sections = [] } = {} }, ref) {
  const { applicationState: { applicationData, applicationId, activeStep, applicantId, totalSteps } = {}, applicationDispatch } = useApplicationContext();
  const { dispatch: dispatchAppContext } = useAppContext();
  const {
    state: { user },
  } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  console.log(sections);

  const [loader, setLoader] = useState(false);

  // React hook form to maintain form state

  const methods = useForm({
    defaultValues: applicationData,
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const {
    formState: { errors, dirtyFields },
    getValues,
    reset,
    setValue,
  } = methods;

  // reset form on change of activeStep
  useEffect(() => {
    resetForm();
    window.scrollTo(0, 0);
  }, [activeStep]);

  // Reset form
  const resetForm = (data = applicationData) => {
    setLoader(true);
    reset(data, {
      keepDirty: false,
      keepErrors: false,
      keepDirtyValues: false,
      keepTouched: false,
      keepValues: false,
    });
    setLoader(false);
  };

  // Next handler
  const handleNext = () => {
    setLoader(true);
    ref?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    setLoader(false);
  };

  const handlePrev = () => {
    setLoader(true);
    applicationDispatch({ type: 'prevStep' });
    setLoader(false);
  };

  // const handleSave = () => {
  //   setLoader(true);
  //   if (!_.isEmpty(errors)) {
  //     //alert "Please resolve all errors"
  //     console.log('Please resolve all errors');
  //     enqueueSnackbar('Please resolve all errors', {
  //       variant: 'error',
  //     });
  //     setLoader(false);
  //     return;
  //   }

  //   const changedValues = getDirtyValues(filterDirtyFields(dirtyFields), getValues());

  //   // if no dirtyValues show alert no changes found
  //   if (_.isEmpty(changedValues)) {
  //     console.log('No Changes found');
  //     setLoader(false);
  //     // enqueueSnackbar('No Changes found', {
  //     //   variant: 'info',
  //     // });
  //     return;
  //   }

  //   const payload = addExtrasFieldsToObject(changedValues, {
  //     Account__c: applicantId,
  //     applicationId,
  //     // parentId: applicantId,
  //   });

  //   console.log(payload, "payload");

  //   dispatchAppContext({ type: 'udpateLoading', value: true });
  //   if (!payload.application) {
  //     payload.application = applicationData.application;
  //   }
  //   postEventApplication({
  //     applicationId,
  //     ...payload,
  //     ...applicationAddCurrentStep(payload.application, applicationData, activeStep, true),
  //     accountId: user?.accountId,
  //   })
  //     .then((res) => {
  //       // on success update context data and active step
  //       if (res?.data?.status === 'true') {
  //         console.log('Saved data successfully');
  //         enqueueSnackbar('Saved data successfully', {
  //           variant: 'success',
  //         });
  //         applicationDispatch({
  //           type: 'update',
  //           payload: { applicationData: res?.data?.result?.data },
  //         });
  //         dispatchAppContext({ type: 'udpateLoading', value: false });
  //         resetForm(res.data);
  //       } 
        
  //     })
  //     .catch((err) => {
  //       //alert user of failure > try again
  //       dispatchAppContext({ type: 'udpateLoading', value: false });
  //       console.log('Updating data failed. Try again');
  //       enqueueSnackbar('Updating data failed. Try again', {
  //         variant: 'error',
  //       });
  //     });
  //   // applicationDispatch({ type: 'nextStep' });
  // };

  const onSubmit = (formValues) => {
    setLoader(true);
    if (!_.isEmpty(errors)) {
      //alert "Please resolve all errors"
      console.log('Please resolve all errors');
      enqueueSnackbar('Please resolve all errors', {
        variant: 'error',
      });
      setLoader(false);
      return;
    }

    // Get values of only dirtyFields

    let changedValues = getDirtyValues(filterDirtyFields(dirtyFields), getValues());
    console.log(changedValues, "changedValues");
    

    // if no dirtyValues show alert no changes found
    if (_.isEmpty(changedValues)) {
      setLoader(false);
      console.log('No Changes found');
      applicationDispatch({ type: 'nextStep' });
      return;
    }

    const payload = addExtrasFieldsToObject(changedValues);
    console.log(payload, "payload");
    // SetLoader
    dispatchAppContext({ type: 'udpateLoading', value: true });

    delete payload.data.startupName;
  
    postSaveStartupQuarterEarningFile(payload.data)
      .then((res) => {
        console.log(res, "formRsdafdes");
        dispatchAppContext({ type: 'udpateLoading', value: false });
        // on success update context data and active step
        if (res?.data?.status === "SUCCESS") {

          console.log(res?.data, "formRes");
          enqueueSnackbar('Data Submitted Successfully', {
            variant: 'info',
          });
          navigate('/ThankingYou', {
            state: {
              title: 'Submitted Successfully',
              description: 'The data has been captured successfully',
            },
          });
          // update context
          // applicationDispatch({
          //   type: 'update',
          //   payload: { applicationData: res?.data?.result?.data },
          // });

          // applicationDispatch({ type: 'nextStep' });
        }
      })
      .catch((err) => {
        dispatchAppContext({ type: 'udpateLoading', value: false });
        //alert user of failure > try again
        enqueueSnackbar("Startup Name already exist." || 'Something went wrong', {
          variant: 'info',
        });
        setLoader(false);
        console.log('Updating data failed. Try again');
      });
    // applicationDispatch({ type: 'nextStep' });
  };

  return (
    <div>
      {loader && <Loader />}
      <form ref={ref} onSubmit={methods.handleSubmit(onSubmit)} style={{ marginBottom: 120 }}>
        <FormProvider {...methods}>
          {sections.map((section, index) => (
            <Section {...section} key={index} isLast={index === sections.length - 1} />
          ))}
        </FormProvider>
      </form>
      {/* // Footer stepper is consistent   */}
      {/* <Footer /> */}
      <KenStepperFooter 
        onNext={handleNext} 
        onPrev={handlePrev} 
        // onSave={handleSave} 
        activeStep={activeStep} 
        totalSteps={totalSteps} />
    </div>
  );
}

export default React.forwardRef(Form);
