import { Divider, Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { useAppContext } from '../../../../Helpers/Contexts/appContext';
import { useApplicationContext } from '../../../../Helpers/Contexts/applicationContext';
// import { deleteApplicationRecords } from '../../Utils/apiServices';
import KenFieldContent from '../KenFieldContent';
import KenButton from '../../../KenButton';
import { removeArrayItem } from '../../../../Helpers/applicationHelper';

export default function FieldArray(props) {
  const { name, fieldsArray = [], defaultItemValue = {}, metaData: { addMultiple = true, minItems = 0, maxLimit = 4 } = {}, divider = true, heading } = props;
  // const { control} = useFormContext();
  const { fields, append, remove } = useFieldArray({
    // control, // control props comes from useForm (optional: if you are using FormContext)
    name: name, // unique name for your Field Array
  });

  const {
    applicationState: { applicationData },
    applicationDispatch,
  } = useApplicationContext();
  const { dispatch: dispatchAppContext } = useAppContext();

  const { enqueueSnackbar } = useSnackbar();
  const { getValues } = useFormContext();

  useEffect(() => {
    if (minItems && fields.length < minItems) {
      for (let index = 0; index < minItems - fields.length; index++) {
        append(defaultItemValue);
      }
    }
    console.log('==> ', minItems, fields);
  }, [minItems, fields]);

  const isRemovable = (index) => {
    if (minItems && index < minItems) {
      return false;
    }
    return true;
  };

  const getField = (fieldSchema, index) => {
    let schema = JSON.parse(JSON.stringify(fieldSchema));
    if (schema?.rule && !/\d/.test(schema.rule.condition.scope)) {
      let scopeName = schema.rule.condition.scope;
      schema.rule.condition.scope = `${name}.${index}.${scopeName}`;
    }
    return <KenFieldContent {...schema} name={`${name}.${index}.${fieldSchema.name}`} />;
  };

  const handleRemove = (itemName, index) => {
    // call api to remove object if recordId exists for this and update context
    const { recordId } = getValues(itemName) || {};
    console.log('removeItem:', getValues(itemName));

    if (recordId) {
      // Call api on success delete the record from form
      if (fields.length > minItems) {
        // dispatchAppContext({ type: 'udpateLoading', value: true });
        // let nameAltered;
        // if (/\d/.test(name)) {
        //   let nameArray = name?.replaceAll(']', '')?.replaceAll('[', '_')?.split('_');
        //   nameAltered = nameArray[nameArray.length - 1];
        // } else {
        //   nameAltered = name;
        // }
        // deleteApplicationRecords({
        //   type: nameAltered,
        //   recordIds: [recordId],
        // })
        //   .then((res) => {
        //     dispatchAppContext({ type: 'udpateLoading', value: false });
        //     if (res?.success) {
        //       remove(index);
        //       // Update applicationData
        //       applicationDispatch({
        //         type: 'update',
        //         payload: {
        //           applicationData: removeArrayItem(applicationData, name, index),
        //         },
        //       });
        //     } else {
        //       //TODO: Show alert
        //       enqueueSnackbar('Failed to remove data', {
        //         variant: 'error',
        //       });
        //     }
        //   })
        //   .catch((err) => {
        //     dispatchAppContext({ type: 'udpateLoading', value: false });
        //     console.log('Remove items: ', err);
        //     //TODO: Show alert
        //     enqueueSnackbar('Failed to remove data', {
        //       variant: 'error',
        //     });
        //   });
      } else {
        enqueueSnackbar(`At least ${minItems} Record is required`, {
          variant: 'error',
        });
      }
    } else {
      if (fields.length > minItems) {
        remove(index);
      } else {
        const nameArr = name?.split('[');
        const nameIndex = nameArr[1]?.slice(0, nameArr[1]?.length - 1);
        let infoName;
        switch (name) {
          case 'certifications':
            infoName = 'certifications';
            break;
          case `educationHistories[${nameIndex}][semesterwiseMarks]`:
            infoName = 'semester';
            break;
          case `educationHistories[${nameIndex}][yearwiseMarks]`:
            infoName = 'Year';
            break;
          case 'applicantRelations':
            infoName = 'Record';
            break;
          case 'workExperiences':
            infoName = 'Record';
            break;
          case 'languages':
            infoName = 'languages';
            break;
          case 'entranceExams':
            infoName = 'Test Record';
            break;
          case 'englishTests':
            infoName = 'qualification Record';
            break;
          default:
            infoName = 'team details';
        }
        enqueueSnackbar(`At least ${minItems} ${infoName} is required`, {
          variant: 'error',
        });
      }
    }
  };

  return (
    <>
      {heading && (
        <Typography
          sx={{
            color: '#092682',
            fontWeight: 500,
            fontSize: '16px',
            marginTop: '16px',
            marginLeft: '48px',
          }}
        >
          {heading} (minimum {minItems})
        </Typography>
      )}
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          {fieldsArray.map((fieldSchema) => getField(fieldSchema, index))}
          {
            (index === fields.length - 1 && index >= 2) ?
              <Grid item xs={12} textAlign={'right'} marginRight={'100px'}>
              <KenButton
                onClick={() => {
                  handleRemove(`${name}.${index}`, index);
                }}
                style={{  backgroundColor: '#4B3776' }}
              >
                Remove
              </KenButton>
            </Grid>
            : ''
          }
          {index !== fields.length - 1 && divider === true && (
            <Grid item xs={12}>
              <Divider variant="middle" style={{ marginBottom: 1, marginTop: 1 }} />
            </Grid>
          )}
        </React.Fragment>
      ))}
      {addMultiple && fields.length < maxLimit && (
        <Grid item xs={12} textAlign={'right'} marginTop={'-5.7%'}>
          <KenButton
            onClick={() => {
              append({});
            }}
            style={{ height: '36px', backgroundColor: '#4B3776' }}
          >
            + Add new
          </KenButton>
        </Grid>
      )}
    </>
  );
}
