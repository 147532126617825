import { Navigate } from "react-router-dom";
import { IReactNode } from "../../interfaces/ICommon";

export const PublicRoute: React.FunctionComponent<IReactNode> = ({ children }) => {
  const userid = localStorage.getItem("contactId");
  if (userid) {
    return <Navigate to={"/home"} />;
  }
  return children;
};
