import { Box, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import KenStepperFooter from './Components/KenStepperFooter';

import { postEventApplication, saveContact } from '../../ApiServices/Api';
import KenSummaryStep from '../../Application/StepperForm/Summary/summary';
import { TABLET_BREAKPOINT } from '../../Helpers/constants';
import FormStep from './Form';
import { useApplicationContext } from '../../Helpers/Contexts/applicationContext';
import { useAppContext } from '../../Helpers/Contexts/appContext';
// import DocumentUpload from './DocumentUpload';
import { enqueueSnackbar } from 'notistack';

// Any special cases like payments,summary based on the stepType will be handled
// NOTE: These special steps should be coupled with any other steps

const useStyles = makeStyles((theme) => ({
  thankYouContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '80%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  title: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '20px',
      'line-height': '23px',
      color: '#000000',
    },
  },
  subTitle: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      'line-height': '150%',
      'text-align': 'center',
      color: '#505F79',
    },
  },
  link: {
    color: '#0000EE',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const logoIcon = require("../../Assets/LogoIcon.jpg");

export default function Stepper({ step }) {
  const { applicationState, applicationDispatch } = useApplicationContext();
  const formRef = useRef();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  console.log('Stepper', step?.stepId);

  // based on stepType return components

  const getStepContent = () => {
    console.log('Stepper : getStepContent', step?.stepId);

    switch (step?.stepId) {
      case 'payment':
        return <PaymentStep />;

      case 'summary':
        return <SummaryStep applicationState={applicationState} applicationDispatch={applicationDispatch} />;

      // case 'documentUpload':
      //   return <DocumentUpload applicationState={applicationState} applicationDispatch={applicationDispatch} />;

      default:
        return (
          <>
            <div className="bg-white Outer-Header-Container">
              <div className="w-100 shadow-sm">
                <div className={`d-flex justify-content-between py-4 ${isMobileScreen ? 'px-2' : 'px-5'} align-items-center`}>
                  <div className="d-flex align-items-center gap-4">
                    <img
                      src={logoIcon}
                      alt="logo"
                      height={'35px'}
                      width={'auto'}
                      style={{ objectFit: 'contain' }}
                      className="link-pointer"
                      onClick={() => {
                        navigate('/home');
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <FormStep step={step} ref={formRef} />;
          </>
        )
    }
  };

  return (
    <>
      {/* render step by id */}
      {/* <KenStepperHeader activeStep={step} /> */}
      <Box alignItems="center" justifyContent="center">
        {getStepContent()}
      </Box>
    </>
  );
}

const PaymentStep = () => {
  return <>Payment Step</>;
};

const SummaryStep = (props) => {
  const { applicationState, applicationDispatch } = props;
  const { dispatch: dispatchAppContext } = useAppContext();

  console.log(applicationState);
  const declaration = applicationState?.declaration;
  console.log(declaration, "declaration")

  const classes = useStyles();
  const navigate = useNavigate();

  const handlePrev = () => {
    applicationDispatch({ type: 'prevStep' });
  };
  const handleNext = () => {
    if(declaration == true) {
      let data = applicationState?.applicationData?.application;
      // data.Form_Submitted__c = true;
      
      data.Application_Status__c = 'Application Complete';
      data.Current_Step__c = 3
      // data.Primary_Contact__c = applicationState?.applicationData?.account?.Primary_Contact__c;
      dispatchAppContext({ type: 'udpateLoading', value: true });

      postEventApplication({
        application: { ...data },
      })
      .then((res) => {
        dispatchAppContext({ type: 'udpateLoading', value: false });
        // on success update context data and active step
        if (res?.data?.result?.success === true) {
          // update context
          applicationDispatch({
            type: 'update',
            payload: { applicationData: res?.data?.result?.data },
          });
          // navigate('/thankYou', {
          //   state: {
          //     title: 'Welcome Aboard!',
          //     description: `Your registration is complete!`,
          //     subTitle: `Check your email for next steps.`,
          //     // link: 'https://turbostart.co/',
          //     // redirect: 'turbostart.co',
          //   },
          // });
          enqueueSnackbar('Your application has been submitted successfully', { variant: 'success' });
          navigate('/home')
          applicationDispatch({ type: 'nextStep' });
          applicationDispatch({ type: 'showHeader' });
        }
      })
      .catch((err) => {
        dispatchAppContext({ type: 'udpateLoading', value: false });
        //alert user of failure > try again
        console.log('Updating data failed. Try again');
      });
    } else {
      enqueueSnackbar('Please select the checkbox', { variant: 'info' });
      return;
    }
  };
  return (
    <>
      <Box sx={{ width: '80vw', margin: '0 auto' }}>
        <KenSummaryStep formJson={applicationState.steps} data={applicationState.applicationData} applicationState={applicationState} applicationDispatch={applicationDispatch} />
      </Box>
      <KenStepperFooter
        totalSteps={applicationState.totalSteps}
        activeStep={applicationState.activeStep}
        onPrev={handlePrev}
        onNext={handleNext}
        isSave={false}
        applicationStatus={applicationState?.applicationData?.application?.Application_Status__c}
      />
    </>
  );
};
