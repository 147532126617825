import { Container, Theme, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Step from '@mui/material/Step';
import StepConnector from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { BsArrowLeft, BsArrowRight, BsCheckCircle, BsCircle } from 'react-icons/bs';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useNavigate, useParams } from 'react-router-dom';
import { getEventDetails, getPendingFormDetails, postEventApplication, postYesBankInitiatePayment } from '../../ApiServices/Api';
import { AppContext } from '../../App';
import { handleHyperText } from '../../Helpers/DateFormatter';
import { filterDirtyFields, getDirtyValues } from '../../Helpers/applicationHelper';
import { IAppContext, IHookFormSubmit } from '../../interfaces/ICommon';
import { IFieldChangeCheck, IFormDataPresent, IGetEventsAPIResponse, IGetEventsResponse, IRecentId } from '../../interfaces/IDashBoard';
import { IAppFormDataProvider, IAppFormDataProviderArray, IAppFormSections, IHeader, IStepperContents, IStepperHookForm } from '../../interfaces/IStepperForm';
import TestStepperFormArray from './default.json';
import { enqueueSnackbar } from 'notistack';

const Header: React.LazyExoticComponent<React.FunctionComponent<IHeader>> = React.lazy(() => import('../../Components/Header/index'));
const StepperSections: React.LazyExoticComponent<React.FunctionComponent<IAppFormSections>> = React.lazy(() => import('./AppFormSections/index'));
const noevent = require('../../Assets/notfound.png');

export const FormContext = React.createContext<IAppFormDataProvider | IAppFormDataProviderArray>({});

const useStyles = makeStyles({
  customeStepper: {
    '& .MuiStepLabel-root .Mui-active': {
      // fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      color: '#000000',
      fontSize: '13px',
    },
    '& .MuiStepLabel-root .Mui-disabled': {
      // fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      color: '#000000',
      fontSize: '13px',
    },
    '& .MuiStepLabel-root .Mui-completed': {
      // fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      color: '#000000',
      fontSize: '13px',
    },
    elevation: 0,
  },
});

const StepperForm: React.FunctionComponent = () => {
  dayjs.extend(LocalizedFormat);
  dayjs.extend(utc);

  const theme: Theme = useTheme();
  const classes = useStyles();
  const { formId, eventId, step } = useParams();
  const isMobileScreen: boolean = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { handleToaster } = React.useContext<IAppContext>(AppContext);
  const stepIndex = localStorage.getItem('step');

  // State Managements
  const [update, setUpdate] = React.useState<number>(0);
  const [activeStep, setActiveStep] = React.useState<number>(stepIndex ? parseInt(stepIndex) : step ? parseInt(`${step}`) : 0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [formDataPresent, setFormData] = React.useState<IFormDataPresent>();
  const [isLoads, setisLoading] = React.useState<boolean>(false);
  const [hasChangedFields, setHasChangedFields] = React.useState<IFieldChangeCheck>({});
  const [hasAttachementsArray, setHasAttachamentsArray] = React.useState<Array<any>>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [stepperFormArray, setStepperFormArray] = React.useState<Array<IStepperContents>>(TestStepperFormArray as Array<IStepperContents>);
  const [recentFormId, setRecentFormId] = React.useState<IRecentId>({
    applicant_id: localStorage.getItem('contactId') as string,
    id: `${formId ? formId : localStorage.getItem('formId') ? localStorage.getItem('formId') : ''}`,
  });
  const [loader, setLoader] = React.useState<Boolean>(false);
  const [declaration, setDeclaration] = React.useState<any>(false);
  const user = JSON.parse(localStorage.getItem('user') as string);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    control,
    reset,
    setValue,
    getValues,
  } = useForm<IStepperHookForm>({
    mode: 'onChange',
  });

  const eventData = useQuery({
    queryKey: ['formMetaData'],
    queryFn: async () => await getEventDetails(eventId as string),
    refetchOnWindowFocus: false,
    select: (data: AxiosResponse<IGetEventsAPIResponse<IGetEventsResponse>>) => {
      return data.data.result;
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationKey: ['postApplication'],
    mutationFn: postEventApplication,
  });

  const handleDay = (date: string | null, monthFormat: string) => {
    let day;
    let month;
    let year;
    if (date) {
      day = dayjs(date).format('DD');
      month = dayjs(date).format(monthFormat);
      year = dayjs(date).format('YYYY');
    }
    return date ? `${day}${handleHyperText(day as string)} ${month}, ${year}` : '-';
  };

  const isStepSkipped: (step: number) => boolean = (step: number) => {
    return skipped.has(step);
  };

  const handleNext: () => void = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack: () => void = () => {
    if (activeStep === 0 && typeof step !== 'string') {
      navigate(`/apply/instruction/${eventId}`);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const ColorlibStepIcon: (props: StepIconProps) => JSX.Element = (props: StepIconProps) => {
    const { completed, active } = props;
    return completed ? <BsCheckCircle size={'20px'} color="#52C15A" /> : active ? <BsCircle size={'20px'} color="#092682" /> : <BsCircle size={'20px'} color="#B3BAC5" />;
  };

  const handleNavigateBack: () => void = () => {
    localStorage.removeItem('formId');
    localStorage.removeItem('step');
    navigate('/home');
  };

  const handleFormSubmit: (data: string | number | boolean, key: React.ChangeEvent<HTMLInputElement>, section: number, fields: number) => void = (
    data: string | number | boolean,
    key: React.ChangeEvent<HTMLInputElement>,
    section: number,
    fields: number
  ) => {
    if (data) {
      const objKey = key.target.name;
      setFormData({ ...formDataPresent, [objKey]: data });
      setHasChangedFields({
        [objKey]: true,
      });
    }
  };

  const handleDataDelete: (key: string) => void = async (key: string) => {
    if (formDataPresent && Object.keys(formDataPresent).length > 0) {
      delete formDataPresent[`${key}`];
      delete hasChangedFields[`${key}`];
    }
  };

  const handleCreatePayload: (formData: any) => any = async (formData: any) => {
    let mainPayload: any = { formData };

    mainPayload = { ...formData };
    const stepperIndex = activeStep > mainPayload?.application?.Current_Step__c ? activeStep : mainPayload?.application?.Current_Step__c;
    mainPayload.application = { ...mainPayload.application, Startup_Application_Form__c: eventId, Current_Step__c: stepperIndex, Applicant__c: localStorage.getItem('contactId') as string };
    if (mainPayload.attachments) {
      const { attachments, ...execptAttachments } = mainPayload;
      mainPayload = execptAttachments;
    }

    if (stepperFormArray.findIndex((el) => el.stepId === 'uploadDocuments') === activeStep) {
      if (formData?.attachments) {
        let count = 0;
        formData?.attachments.forEach((el: any) => {
          if (el?.Document_Requirement__c === 'Mandatory') {
            if (!el?.Submitted_File_Id__c) {
              count++;
            }
          }
        });
        if (count !== 0) {
          return {
            error: true,
            errorMessage: 'Please upload Mandatory Documents',
          };
        } else {
          mainPayload.application['Current_Step__c'] = activeStep + 1;
        }
      }
    }

    if (stepperFormArray.findIndex((el) => el.stepId === 'payment') === activeStep) {
      if (formData?.application?.Fee_Paid__c === false) {
        return {
          error: true,
          errorMessage: 'Please pay the fee',
        };
      }
    }

    if (mainPayload?.teamMembers) {
      let teamMemberArray: any[] = [];
      mainPayload?.teamMembers?.forEach((el: any) => {
        if (el?.LastName !== '') {
          teamMemberArray.push(el);
        }
      });
      mainPayload.teamMembers = teamMemberArray;
    }

    if (stepperFormArray.length - 1 === activeStep) {
      mainPayload.application['Current_Step__c'] = activeStep;
      mainPayload.application['Application_Status__c'] = 'Application Complete';
    }

    return mainPayload;
  };

  const handleSuccessOnFormSubmit: (data: AxiosResponse<any>) => any = (data: AxiosResponse<any>) => {
    reset();
    setHasChangedFields({});
    setFormData(undefined);
    setRecentFormId({
      applicant_id: `${data.data.result?.data?.application?.Applicant__c ? data.data.result?.data?.application?.Applicant__c : ''}`,
      id: data.data.result.data?.application.Id ? data.data.result.data.application.Id : '',
    });
    localStorage.removeItem('formId');
    localStorage.removeItem('step');
    localStorage.setItem('formId', data.data.result.data?.application.Id);
    localStorage.setItem('step', data.data.result.data?.application.Current_Step__c);
    setUpdate(update + 1);
  };

  // Save as Draft submit
  const saveData: () => void = async () => {
    const changedValues = getDirtyValues(filterDirtyFields(dirtyFields), getValues());
    if (Object.keys(changedValues).length > 0) {
      if (!changedValues.application) {
        changedValues.application = getValues().application;
      }
      const { attachments, ...execptAttachments } = changedValues;
      mutate(execptAttachments, {
        onSuccess(data: AxiosResponse<any>) {
          handleToaster(true, 'Data Saved Successfully', 'success');
          handleSuccessOnFormSubmit(data);
        },
        onError() {
          handleToaster(true, 'Something went wrong', 'error');
        },
      });
    } else {
      setUpdate(update + 1);
    }
  };

  // Main Form Submit
  const submitForm: (formData: any) => void = async (formData: any) => {
    if (eventData?.data?.data?.program?.feeAmount !== null && activeStep === stepperFormArray.length - 1) {
      handlePayment();
    } else {
      const mainPayload = await handleCreatePayload(formData);
      console.log(mainPayload, hasChangedFields);
      if (mainPayload.error === true) {
        handleToaster(true, mainPayload.errorMessage, 'error');
      } else {
        if ((Object.keys(dirtyFields).length > 0 || stepperFormArray.length - 1 === activeStep) && mainPayload) {
          mutate(mainPayload, {
            onSuccess(data: AxiosResponse<any>) {
              handleSuccessOnFormSubmit(data);
              if (stepperFormArray.length - 1 === activeStep) {
                handleToaster(true, 'Application Has been submitted Successfully', 'success');
                navigate('/home');
              }
              if (stepperFormArray.length - 1 !== activeStep) {
                handleNext();
              }
            },
            onError() {
              handleToaster(true, 'Something went wrong', 'error');
            },
          });
        } else if (getValues()?.application?.Current_Step__c !== activeStep) {
          mutate(mainPayload, {
            onSuccess(data: AxiosResponse<any>) {
              handleSuccessOnFormSubmit(data);
              if (stepperFormArray.length - 1 === activeStep) {
                handleToaster(true, 'Application Has been submitted Successfully', 'success');
                navigate('/home');
              }
              if (stepperFormArray.length - 1 !== activeStep) {
                handleNext();
              }
            },
            onError() {
              handleToaster(true, 'Something went wrong', 'error');
            },
          });
        } else {
          handleNext();
          setUpdate(update + 1);
        }
      }
    }
  };

  const handlePayment = async () => {
    setLoader(true);
    await postYesBankInitiatePayment({
      txnid: '',
      amount: eventData?.data?.data?.program?.feeAmount,
      name: user?.contactName,
      email: user?.contactEmail,
      contactId: user?.contactId,
      phone: '6383522927',
      applicationId: formId,
      productinfo: '1',
      surl: '',
      furl: '',
      udf1: ``,
      udf2: '',
      udf3: '',
      udf4: '',
      udf5: '',
      address1: '',
      address2: '',
      city: 'Bangalore',
      state: 'Karanataka',
      feeName: 'Application Fee',
      country: 'India',
      zipcode: '',
      sub_merchant_id: '',
      unique_id: `${window.location.href?.replace(/^https?:\/\//, '')}`,
      split_payments: '',
      customer_authentication_id: '',
      udf6: '',
      udf7: '',
      udf8: '',
      udf9: '',
      udf10: '',
    })
      .then((res) => {
        if (res?.status === 0 && res?.data === 'Parameter validation failed') {
          setLoader(false);
          enqueueSnackbar('Something went wrong', { variant: 'error' });
        } else if (res?.success === true) {
          const payLink = res?.url;
          window.open(payLink, '_self');
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
      });
  };

  React.useEffect(() => {
    setisLoading(true);
    const recentId = formId ? formId : localStorage.getItem('formId');
    if (typeof recentId === 'string') {
      getPendingFormDetails(recentId)
        .then((res) => {
          const recentForm: IHookFormSubmit = {
            ...res.data?.result?.data,
          };

          // To avoid the unneccesary contents from the API response
          delete recentForm.SystemModstamp;
          delete recentForm.Application_Status__c;
          delete recentForm.Revenue_Generated__c;
          delete recentForm.SystemModstamp;
          delete recentForm.attributes;
          delete recentForm.Name;
          delete recentForm.Id;
          delete recentForm.Startup_Application_Form__c;
          delete recentForm.Current_Step__c;
          setFormData(recentForm);

          for (const key in recentForm) {
            setValue(key, recentForm[key]);
          }
          setisLoading(false);
        })
        .catch(() => {
          setisLoading(false);
          localStorage.removeItem('formId');
          localStorage.removeItem('step');
          handleToaster(true, 'Invalid Form ID', 'error');
          navigate('/home');
        });
    }
    setisLoading(false);
  }, [formId, update, navigate, setValue, handleToaster]);

  function handleStepper() {
    if (stepperFormArray.length > 0) {
      for (let i = 0; i < stepperFormArray.length; i++) {
        if (i === activeStep) {
          return <>{<StepperSections handleSuccessOnFormSubmit={handleSuccessOnFormSubmit} key={stepperFormArray[i]?.stepId} />};</>;
        }
      }
    }
  }

  const isApplicationCompleted = formDataPresent && formDataPresent?.application?.Application_Status__c !== 'Application Incomplete';

  const AppFormProvider: IAppFormDataProvider = {
    control: control,
    currentValue: hasAttachementsArray,
    data: stepperFormArray[activeStep] as IStepperContents,
    error2: errors,
    getValue: getValues,
    handleChangeEvent: handleFormSubmit,
    handleKeyDelete: handleDataDelete,
    register2: register,
    setValue: setValue,
    formJson: JSON.stringify(stepperFormArray),
    formData: eventData?.data?.data,
    currentStep: activeStep,
    setLoader: setLoader,
    setDeclaration: setDeclaration,
    declaration: declaration,
  };

  return (
    <FormContext.Provider value={AppFormProvider}>
      <Header Heading="Application" divider={false} />
      <Box className="w-100">
        <Box
          component={'div'}
          height={'220px'}
          sx={{
            backgroundImage: `url(${eventData.data?.data?.program.bannerImage ? eventData.data?.data?.program.bannerImage : noevent})`,
            backgroundPosition: '50% 20%',
          }}
          className="Event-header"
        >
          <Box component={'div'} bgcolor={'rgba(0, 0, 0, 0.30)'} className="p-5" height={'220px'} width={'100%'}>
            <div className="d-flex align-items-center gap-2">
              <BsArrowLeft fontSize={'12'} color="#fff" />
              <h6 className="m-0 p-0 font-12-white-thin link-pointer" onClick={() => handleNavigateBack()}>
                Back to Dashboard
              </h6>
            </div>
            <Box className="pt-3 pb-4">
              {/* <h2 className="font-26-white-normal m-0 p-0">{eventData.data?.data?.formName}</h2> */}
              {/* {eventData.data?.data?.endDate && !isApplicationCompleted && <h6 className="font-12-white-thin m-0 p-0 my-3">Apply Before {handleDay(eventData.data?.data?.endDate, 'MMM')}</h6>} */}
            </Box>
          </Box>
        </Box>
        <Box component={'div'} className={`bg-white w-100`}>
          <form className="w-100" onSubmit={handleSubmit(submitForm)}>
            <Box component={'div'} padding={isMobileScreen ? '0px 5px' : '0px 150px'} className={`bg-white py-3`}>
              <Stepper activeStep={activeStep} connector={<StepConnector style={{ visibility: 'hidden', width: '20px' }} />} className="scroll-none" style={{ overflow: 'scroll' }}>
                {stepperFormArray.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step className={`text-nowrap stepper-text ${classes.customeStepper}`} key={label.stepName} {...stepProps}>
                      <StepLabel className="m-0 p-0 stepper-text" StepIconComponent={ColorlibStepIcon} {...labelProps}>
                        {label.stepName}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
            {!(isLoading || eventData.isLoading || isLoads || loading || stepperFormArray.length === 0) && formDataPresent && !loader ? (
              <div className="pb-5 px-2 w-100">{handleStepper()}</div>
            ) : (
              <Container maxWidth="md" style={{ textAlign: 'start' }}>
                <Skeleton width="40%" height="30px" style={{ marginTop: '50px', marginBottom: '20px' }} />
                <Skeleton count={2} inline width="45%" wrapper={({ children }) => <span style={{ marginRight: '0.5rem' }}>{children}</span>} />
                <Skeleton width="40%" height="30px" style={{ marginTop: '50px', marginBottom: '20px' }} />
                <Skeleton count={2} inline width="45%" wrapper={({ children }) => <span style={{ marginRight: '0.5rem' }}>{children}</span>} />
              </Container>
            )}
            {isApplicationCompleted ? null : (
              <Grid
                container
                lg={12}
                md={12}
                sm={12}
                width={'100%'}
                className="bg-white px-2"
                position={'fixed'}
                bottom={0}
                boxShadow={'0px 0px 1px 0px rgba(0, 0, 0, 0.04), 2px 0px 6px 0px rgba(0, 0, 0, 0.04), 10px 0px 20px 0px rgba(0, 0, 0, 0.04)'}
              >
                <Grid item lg={2} md={0} sm={0} xs={0} />
                <Grid item lg={8} md={12} sm={12} xs={12}>
                  <Box component={'div'} className="py-3 d-flex justify-content-between">
                    <button
                      type="button"
                      className="font-black-16-normal m-0 p-0 primary-text-button"
                      onClick={() => {
                        handleBack();
                      }}
                      style={activeStep === 0 ? { cursor: 'not-allowed' } : {}}
                      disabled={activeStep === 0}
                    >
                      {!isMobileScreen ? (
                        <>
                          <BsArrowLeft size={'20px'} className="mx-2" />
                          Previous
                        </>
                      ) : (
                        <>
                          <span style={{ marginLeft: '8px' }}>Prev</span>
                        </>
                      )}
                    </button>
                    <Box component={'div'} className="d-flex gap-4">
                      {!(activeStep === stepperFormArray.length - 1) && (
                        <>
                          {(isDirty && Object.keys(errors).length === 0 && formDataPresent && Object.keys(formDataPresent).length > 0) || stepperFormArray.length - 1 === activeStep ? (
                            <button
                              type="button"
                              onClick={() => {
                                saveData();
                              }}
                              className="font-black-16-normal m-0 p-0 px-4 py-2 primary-outlined-button rounded-1"
                            >
                              {!isMobileScreen ? 'Save as Draft' : 'Save'}
                            </button>
                          ) : null}
                        </>
                      )}
                      <button
                        type="submit"
                        className="font-black-16-normal m-0 p-0 px-4 py-2 primary-contained-button rounded-1"
                        disabled={!(activeStep === stepperFormArray.length - 1 && declaration === false) ? false : true}
                      >
                        {activeStep === stepperFormArray.length - 1 ? (eventData?.data?.data?.program?.feeAmount !== null ? 'Submit & Pay Fee' : 'Submit') : 'Next'}
                        &nbsp;&nbsp;
                        {!isMobileScreen && <BsArrowRight size={'20px'} />}
                      </button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={2} md={0} sm={0} xs={0} />
              </Grid>
            )}
          </form>
        </Box>
      </Box>
    </FormContext.Provider>
  );
};

export default StepperForm;
