import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

/**
 * Helps in getting a number say 4 as 4th 
 * @param number 11
 * @returns 11th
 */
export const handleHyperText: (number: string) => string = (number: string) => {
  const lastString = number.charAt(number.length - 1);
  let pronouns = "";
    if (lastString === "1" && number !== "11") {
      pronouns = "st";
    } else if (lastString === "2" && number !== "12") {
      pronouns = "nd";
    } else if (lastString === "3" && number !== "13") {
      pronouns = "rd";
    } else {
      pronouns = "th";
    }
    return pronouns;
  };


/**
 * 
 * @param date 11/06/22 
 * @returns 11th June 2022 
 */
export const dateFormatterString: (date: string | null) => string = (date: string | null) => {
    dayjs.extend(LocalizedFormat);
    let day;
    let month;
    let year;
    if (date) {
      day = dayjs(date).format("DD");
      month = dayjs(date).format("MMMM");
      year = dayjs(date).format("YYYY");
    }
    return date ? `${day}${handleHyperText(day as string)} ${month}, ${year}` : "-";
  };