import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertColor } from "@mui/material/Alert";
import { BiCheck } from "react-icons/bi";
import propType from "prop-types";
import { RxCross2 } from "react-icons/rx";
import { Theme, useMediaQuery, useTheme } from "@mui/material";

interface IToaster {
  open: boolean;
  status: "success" | "error" | "info" | "warning" | string;
  message: string;
  handleSnackClose: () => void;
}

const Toaster: React.FunctionComponent<IToaster> = ({ open, status, message, handleSnackClose }) => {
  const toastStatus = status as AlertColor;
  const theme: Theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  return open ? (
    <Snackbar open={open} autoHideDuration={3000} onClose={handleSnackClose} anchorOrigin={{ vertical: isMobileScreen ? "top" : "bottom", horizontal: isMobileScreen ? "center" : "left" }} className={`snack-bar-style ${isMobileScreen ? "w-100" : "w-25"}`}>
      <Alert variant="filled" onClose={handleSnackClose} severity={toastStatus} className="w-100 p-3 py-2" action={<RxCross2 onClick={handleSnackClose} fontSize={"20px"} color="inherit" className="mx-2 my-auto link-pointer" />} icon={<BiCheck />}>
        {message}
      </Alert>
    </Snackbar>
  ) : null;
};

Toaster.propTypes = {
  open: propType.bool.isRequired,
  status: propType.string.isRequired,
  message: propType.string.isRequired,
  handleSnackClose: propType.func.isRequired,
};

export default Toaster;
