import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const root = document.querySelector(':root') as HTMLElement;
const primary: string = getComputedStyle(root).getPropertyValue('--primaryTheme');

export default function Loader() {
  return (
    <Box component={'div'} className={'d-flex align-items-center justify-content-center loader-container'} data-testid="KenLoader" sx={{ opacity: 0.5 }}>
      <CircularProgress sx={{ color: `${primary}!important` }} />
    </Box>
  );
}
