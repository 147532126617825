import React, { createContext, useState } from 'react';
import Themes from './Helpers/Themes';
import { Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Toaster from './Components/Toaster';
import { AlertColor } from '@mui/material';
import { ApiConfig } from './ApiServices/Api';
import { IAppContext, IToaster } from './interfaces/ICommon';
import { PrivateRoute } from './Components/PrivateRoute';
import { PublicRoute } from './Components/PublicRoute';
import { SnackbarProvider } from 'notistack';
import ApplicationFormNew from './Application/ApplicationForm/Application';
import ThankYouPage from './Global_Components/KenApplicationForm/Components/ThankYouPage'

const Dashboard: React.LazyExoticComponent<React.FunctionComponent> = React.lazy(() => import('./Application/DashBoard/index'));
const EventDetails: React.LazyExoticComponent<React.FunctionComponent> = React.lazy(() => import('./Application/EventsDetails/index'));
const InstructionForm: React.LazyExoticComponent<React.FunctionComponent> = React.lazy(() => import('./Application/StepperForm/InstructionForm/index'));
const Stepper: React.LazyExoticComponent<React.FunctionComponent> = React.lazy(() => import('./Application/StepperForm/index'));
const CustomLogin: React.LazyExoticComponent<React.FunctionComponent> = React.lazy(() => import('./Application/Custom/index'));
const Loader: React.LazyExoticComponent<React.FunctionComponent> = React.lazy(() => import('./Components/Loaders/index'));
const LoginPage: React.LazyExoticComponent<React.FunctionComponent> = React.lazy(() => import('./Application/Login/index'));
const SignUpPage: React.LazyExoticComponent<React.FunctionComponent> = React.lazy(() => import('./Application/SignUp/index'));

const clientQuery = new QueryClient();

export const AppContext: React.Context<IAppContext> = createContext<IAppContext>({
  handleToaster(open, message, severity) {},
});

const MainLayout: React.FunctionComponent = () => {
  const [openToaster, setOpenToaster] = useState<IToaster>({
    message: '',
    open: false,
    severity: 'info',
  });

  const handleToaster: (open: boolean, message: string, severity: AlertColor) => void = (open: boolean, message: string, severity: AlertColor) => {
    setOpenToaster({
      message: message,
      open: open,
      severity: severity,
    });
  };

  const contextData: IAppContext = {
    handleToaster: handleToaster,
  };

  return (
    <QueryClientProvider client={clientQuery}>
      <AppContext.Provider value={contextData}>
        <SnackbarProvider autoHideDuration={5000}>
          <Toaster
            open={openToaster.open}
            handleSnackClose={() => {
              setOpenToaster({
                message: '',
                open: false,
                severity: 'info',
              });
            }}
            message={openToaster.message}
            status={openToaster.severity}
          />
          <React.Suspense fallback={<Loader />}>
            <Themes>
              <Routes>
                <Route
                  path="/"
                  index
                  element={
                    <PublicRoute>
                      <LoginPage />
                    </PublicRoute>
                  }
                />
                <Route
                  path="/signup"
                  element={
                    <PublicRoute>
                      <SignUpPage />
                    </PublicRoute>
                  }
                />
                <Route
                  path="/home"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/events/:id"
                  element={
                    <PrivateRoute>
                      <EventDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/application/:eventId?/:formId?/:step?"
                  element={
                    <PrivateRoute>
                      <Stepper />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/new"
                  element={
                    <PublicRoute>
                      <CustomLogin />
                    </PublicRoute>
                  }
                />
                <Route
                  path="/applicationNew"
                  element={
                    <PublicRoute>
                      <ApplicationFormNew />
                    </PublicRoute>
                  }
                />
                <Route
                  path="/ThankingYou"
                  element={
                    <PublicRoute>
                      <ThankYouPage />
                    </PublicRoute>
                  }
                />
                <Route
                  path="/apply/instruction/:eventId"
                  element={
                    <PrivateRoute>
                      <InstructionForm />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </Themes>
          </React.Suspense>
        </SnackbarProvider>
      </AppContext.Provider>
    </QueryClientProvider>
  );
};

const App: React.FunctionComponent = () => {
  ApiConfig();
  return <MainLayout />;
};

export default App;
