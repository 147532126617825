import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { City, Country, State } from 'country-state-city';
import { useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import getValidation from '../../../../Helpers/validationHelper';
import KenInput from '../../../KenInput';
import KenPhoneInput from '../../../KenPhoneInput';
import KenRadioGroup from '../../../KenRadioGroup';
import KenSelect, { CustomIcon } from '../../../KenSelect';
import KenFieldArray from '../KenFieldArray/FieldArray';
import phone from 'phone';
import { useApplicationContext } from '../../../../Helpers/Contexts/applicationContext';
// import KenDateField from '../../../KenDateField';

const useStyles = makeStyles((theme) => ({
  phoneContainerClass: {
    '& .phone-input': {
      height: '57px !important',
      width: '100%',
    },
  },
  inputFieldLabelClass: {
    color: '#505F79',
    'font-size': '12px',
    'line-height': '16px',
    'margin-bottom': '6px',
    'text-transform': 'capitalize',
    fontWeight: 500,
  },
  formFieldLabel: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '100%',
    color: '#505F79',
  },
  datePickerClass: {
    '&  button.MuiIconButton-edgeEnd': {
      marginLeft: 0,
      marginRight: theme.spacing(1),
    },
  },
  menuPaper: {
    maxHeight: '300px !important',
    boxSizing: 'border-box',
    borderTop: '8px solid #ffffff',
    borderBottom: '8px solid #ffffff',
  },
}));

const KenWidget = (props) => {
  const {
    ui, //TODO: ui.divider added only for select and input. Add it for all widgets.
    type,
    name,
    // label,
    description,
    fieldId,
    lastItem,
    // isFormValid,
    // options,
    validations = {},
    fieldsArray,
    validationType,
    // getFormValues,
  } = props;
  // TODO: Create a new component for upload and Move this context
  const { applicationState, application } = useApplicationContext();
  const classes = useStyles();
  const methods = useFormContext();
  const control = methods.control;
  const [objectName, setObjectName] = useState();
  const [fieldName, setFieldName] = useState();
  const errors = methods.formState.errors;
  //   console.log("errors in form", methods.formState.errors);
  const [size, setSize] = useState({
    xs: ui?.grid?.xs || 12,
    sm: ui?.grid?.sm || 6,
    md: ui?.grid?.md || 6,
    lg: ui?.grid?.lg || 6,
  });

  useEffect(() => {
    switch (ui?.gridType) {
      case 'fullRow':
        setSize({ xs: 12, sm: 12, md: 12, lg: 12 });

        break;

      case 'halfRow':
        setSize({ xs: 12, sm: 6, md: 6, lg: 6 });
        break;

      default:
        break;
    }
  }, [ui?.gridType]);

  const getSplicedName = (combinedName) => {
    const replaceBrackets = combinedName.replace(/[[\]']+/g, ' ');
    const splitStr = replaceBrackets?.split(' ');
    if (Array.isArray(splitStr) && splitStr.length > 0) {
      setObjectName(splitStr[0]);
      setFieldName(splitStr[1]);
    }
  };

  useEffect(() => {
    getSplicedName(name);
  }, [name]);

  useEffect(() => {
    // console.log("errors", errors);
    for (const [key, value] of Object.entries(errors)) {
      console.log(`${key}: ${value}`);
    }

    const isEmpty = Object.keys(errors).length === 0;
    if (isEmpty) {
      // isFormValid(true);
    } else {
      // isFormValid(false);
    }
  }, [errors]);

  const getValidations = (validate) => {
    const validateCopy = { ...validate };
    console.log(validate, validationType);
    if (validateCopy?.pattern && typeof validateCopy?.pattern?.value !== 'object') {
      validateCopy.pattern.value = getValidation(validationType);
    }
    console.log(validateCopy);
    return validateCopy;
  };

  const getElementError = (data, name) => {
    const allData = Object.values(data);
    const index = allData?.findIndex((elem) => elem?.ref?.name === name);
    if (index > -1) {
      return allData[index]?.message;
    } else {
      return data?.message;
    }
  };

  const phoneValidatonCheck = (value) => {
    const validPhone = phone(value);
    if(validPhone.isValid == false) {
      return 'Please enter valid Phone number';
    }
    
    return true;
  }

  const getField = (type) => {
    switch (type) {
      case 'select':
        return (
          <>
            {props?.options?.length > 0 ? (
              <Controller
                name={`${name}`}
                control={methods.control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <KenSelect
                    textFieldProps={{
                      SelectProps: {
                        IconComponent: CustomIcon,
                        MenuProps: {
                          PaperProps: {
                            className: classes.menuPaper,
                          },
                        },
                      },
                    }}
                    {...props}
                    labelClassName={classes.formFieldLabel}
                    onChange={onChange}
                    value={value}
                    error={error?.message}
                  />
                )}
                {...methods.register(name, validations)}
              />
            ) : (
              ''
            )}
          </>
        );
      case 'input':
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field, fieldState: { error } }) => (
              <>
                <KenInput {...props} {...field} error={error?.message} />
              </>
            )}
            {...methods.register(name, getValidations(validations))}
          />
        );

      case 'textArea':
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field, fieldState: { error } }) => (
              <>
                <KenInput
                  {...props}
                  {...field}
                  // labelClassName={classes.formFieldLabel}
                  multiline={true}
                  minRows={3}
                  error={error?.message}
                />
              </>
            )}
            {...methods.register(name, validations)}
          />
        );

      case 'phone':
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            rules={{
              required: 'This field is Required',
              validate: phoneValidatonCheck,
              maxLength: {
                value: 13,
                message: 'Please input phone number',
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <KenPhoneInput phoneContainerClass={classes.phoneContainerClass} inputFieldLabelClass={classes.formFieldLabel} {...props} onChange={onChange} value={value} error={error?.message} />
              );
            }}
            {...methods.register(name, validations)}
          />
        );
      case 'radio':
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <KenRadioGroup {...props} labelClassName={classes.formFieldLabel} onChange={onChange} value={value} error={error?.message} />
            )}
            {...methods.register(name, validations)}
          />
        );

      case 'addMultiple': {
        return (
          <KenFieldArray
            {...props}
            // onChange={onChange}
            // value={value}
            bunchOfFields={fieldsArray}
            fieldArrayName={`${name}`}
            defaultObject={fieldsArray && fieldsArray[0]}
            error={
              methods.formState.errors &&
              methods.formState.errors[objectName] &&
              methods.formState.errors[objectName][fieldName] &&
              getElementError(methods.formState.errors[objectName][fieldName], name)
            }
            {...methods.register(name, validations)}
          />
          // <Controller
          // 	name={`${name}`}
          // 	control={methods.control}
          // 	render={({ field: { onChange, value } }) => (
          // 		<KenFieldArray
          // 			{...props}
          // 			onChange={onChange}
          // 			value={value}
          // 			bunchOfFields={fieldsArray}
          // 			fieldArrayName={`${name}`}
          // 			defaultObject={fieldsArray && fieldsArray[0]}
          // 		/>
          // 	)}
          // />
        );
      }

      case 'array':
        return <KenFieldArray {...props} />;

      case 'address':
        return <Address {...props} />;

      case 'date':
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              let newProps = { ...props };
              console.log(props, "propscalc");
              if (props?.fieldValidation) {
                if (props?.fieldValidation?.value === 'GREATER') {
                  newProps['minDate'] = typeof methods.getValues(props?.fieldValidation?.scope) === "string" ? methods.getValues(props?.fieldValidation?.scope) : props?.minDate;
                } else {
                  newProps['maxDate'] = typeof methods.getValues(props?.fieldValidation?.scope) === "string" ? methods.getValues(props?.fieldValidation?.scope) : props?.maxDate;
                }
              }

              return (
                <>
                  {/* <KenDateField {...field} {...newProps} error={error?.message} /> */}
                  <KenInput
                    {...props}
                    {...field}
                    // disableKeyDown={true}
                    error={error?.message}
                  />
                </>
              )
            }}
            {...methods.register(name, validations)}
          />
        );

      default:
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field, fieldState: { error } }) => (
              <>
                <KenInput {...props} {...field} error={error?.message} />
              </>
            )}
            {...methods.register(name, validations)}
          />
        );
    }
  };

  return type !== 'addMultiple' && type !== 'address' && type !== 'array' ? (
    <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg} textAlign="left">
      {props && getField(type)}
    </Grid>
  ) : (
    <>{props && getField(type)}</>
  );
};
export default KenWidget;

const Address = function (props) {
  const { options: fields = [], name } = props;
  // handle all country, state,city, logic and options to hide

  // const [fields, setFields] = useState(fields);

  // watch country,state and update the relavent

  const { getValues } = useFormContext();

  const { mailingCountry, mailingState, country: addressCountry, state: addressState, Country_Of_Registration__c, BillingCountry, BillingState } = getValues(`${name}`) || {};
  const watchCountry = useWatch(`${name}`);

  const [country, setCountry] = useState();

  useEffect(() => {
    // setValue(`${name}['mailingState]`, '');
    // setValue(`${name}['mailingDistrict]`, '');
    //setCountryCode for state, city options

    const c = Country.getAllCountries().find((el) => el.name === mailingCountry || el.name === addressCountry || el.name === BillingCountry);

    setCountry(c);
  }, [mailingCountry, addressCountry, Country_Of_Registration__c, BillingCountry]);

  useEffect(() => {
    // if dirty field
    console.log('useEffect>> watchCountry', watchCountry);
    // setValue(`${name}['mailingDistrict]`, '');
  }, [watchCountry]);

  const getOptions = ({ options, fieldId }) => {
    // if (options) {
    //   return options;
    // }

    // if country field return country options

    switch (fieldId) {
      case 'mailingCountry':
        return Country.getAllCountries().map(({ name: n }) => ({
          label: n,
          value: n,
        }));

      case 'country':
        return Country.getAllCountries().map(({ name: n }) => ({
          label: n,
          value: n,
        }));

      case 'Country_Of_Registration__c':
        return Country.getAllCountries().map(({ name: n }) => ({
          label: n,
          value: n,
        }));

      case 'BillingCountry':
        return Country.getAllCountries().map(({ name: n }) => ({
          label: n,
          value: n,
        }));

      case 'mailingState':
        if (country) {
          return State.getStatesOfCountry(country?.isoCode).map(({ name }) => ({
            label: name,
            value: name,
          }));
        }
        return [];

      case 'BillingState':
        if (BillingCountry) {
          return State.getStatesOfCountry(country?.isoCode).map(({ name }) => ({
            label: name,
            value: name,
          }));
        }
        return [];

      case 'state':
        if (country) {
          return State.getStatesOfCountry(country?.isoCode).map(({ name }) => ({
            label: name,
            value: name,
          }));
        }
        return [];

      case 'mailingDistrict':
        console.log('mailingDistrict: ', mailingState);
        if (mailingState) {
          const selectedStateCode = State.getAllStates().find((el) => el.name === mailingState)?.isoCode;
          return [...City.getCitiesOfState(country?.isoCode, selectedStateCode).map((el) => ({ label: el?.name, value: el?.name })), { label: 'Others', value: 'Others' }];
        }
        return [];

      case 'city':
        console.log('mailingDistrict: ', addressState);
        if (addressState) {
          const selectedStateCode = State.getAllStates().find((el) => el.name === addressState)?.isoCode;
          return City.getCitiesOfState(country?.isoCode, selectedStateCode).map((el) => ({ label: el?.name, value: el?.name }));
        }
        return [];

      case 'BillingCity':
        console.log('mailingDistrict: ', BillingState);
        if (BillingState) {
          const selectedStateCode = State.getAllStates().find((el) => el.name === BillingState)?.isoCode;
          return City.getCitiesOfState(country?.isoCode, selectedStateCode).map((el) => ({ label: el?.name, value: el?.name }));
        }
        return [];

      default:
        return [];
    }
  };

  return (
    <>
      {fields.map((field) => (
        <>{getOptions(field).length > 0 && <KenWidget {...field} name={`${name}[${field?.name}]`} options={getOptions(field)} />}</>
      ))}
    </>
  );
};
