import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useApplicationContext } from '../../../../Helpers/Contexts/applicationContext';
import { getScopeKeys, hasEnableRule, hasShowRule, isEnabled, isVisible } from '../../ruleHelper';
import KenWidget from '../KenWidget';

function KenFieldContent(props) {
  // const { data, formJSON, activeStep, currentStepDetails } = props;
  // console.log("props in  KenFieldContent", props);
  const { rule, metaData, options } = props;
  // If rule is present
  const { getValues, watch, unregister } = useFormContext();
  const disabledProps = hasEnableRule(props) ? { disabled: !isEnabled(props.rule, {}, getValues) } : {};

  // if options are to are dynamic
  const [extras, setExtras] = useState({});
  const {
    applicationState: { formOptions = {} },
  } = useApplicationContext();

  const watchFields = watch(getScopeKeys(rule));
  // const [ruleValues, setRuleValues] = useState({ isVisible: true });
  const ruleValues = useMemo(() => getRuleVaues(watchFields, rule, getValues), [watchFields, rule]);

  // console.log('KenField Content: ===========', props?.name);
  // useEffect(() => {
  //   if (rule && getScopeKeys(rule)?.length > 0) {
  //     console.log('>>>>>>>>>>', getScopeKeys(rule), watchFields);
  //     setRuleValues({
  //       isVisible: isVisible(rule, {}, getValues),
  //     });
  //   }
  // }, [watchFields]);

  // Dynamic options values
  useEffect(() => {
    console.log('>>> Extras ');
    if (metaData?.optionsKey) {
      // getOptions from context and set to extras
      const addedOptions = formOptions[metaData?.optionsKey] || [];
      setExtras({
        options: [...options, ...addedOptions],
        extraProps: true,
      });
    }
  }, [metaData, options, formOptions]);

  useEffect(() => {
    if (ruleValues?.isVisible === false) {
      unregister(props?.name);
    }
  }, [ruleValues?.isVisible === false]);

  return <>{hasShowRule(props) ? ruleValues.isVisible && <KenWidget {...props} {...extras}></KenWidget> : <KenWidget {...props} {...disabledProps} {...extras}></KenWidget>}</>;
}

const getRuleVaues = function (watchFields, rule, getValues) {
  if (rule && getScopeKeys(rule)?.length > 0) {
    console.log('>>>>>>>>>>', getScopeKeys(rule), watchFields);
    return {
      isVisible: isVisible(rule, {}, getValues),
    };
  }

  return {};
};

export default KenFieldContent;
