import { Box, Divider, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ErrorIcon from '../../Assets/Icons/error.png';
import KenIcon from '../KenIcon';
import KenTextField from '../KenTextField';
import KenTextLabel from '../KenTextLabel';

const useStyles = makeStyles({
  errorStyles: {
    backgroundColor: '#ffffff',
  },
  customIcon: {
    'user-select': 'none',
    height: '1em',
    display: 'inline-block',
    '-webkit-flex-shrink': '0',
    '-ms-flex-negative': '0',
    'flex-shrink': '0',
    '-webkit-transition': 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    'font-size': '1.5rem',
    position: 'absolute',
    right: '20px !important',
    top: 'calc(50% - 0.5em)',
    'pointer-events': 'none',
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

export function CustomIcon(props) {
  const classes = useStyles();

  return (
    <svg className={`${classes.customIcon} ${props.className}`} width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L6 6L11 1" stroke="#73706E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

export default function KenSelect(props) {
  const { label, required, optionalLabel, error, options, options_design, handleChange, value, textFieldProps, labelClassName, ui, instruction, helperText, ...otherProps } = props;

  return (
    <>
      {label && <KenTextLabel label={label} required={required} instruction={instruction} optionalLabel={optionalLabel} helperText={helperText} />}

      <Box display="flex" alignItems="flex-start">
        <KenTextField id="outlined-select-currency" select value={value} onChange={handleChange} error={error} {...otherProps} {...textFieldProps}>
          {options?.map((option) => {
            return option?.label && option?.value ? (
              <MenuItem
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  padding: '10px',
                }}
                key={option?.value}
                value={option?.value}
              >
                <Box display="flex">
                  {option?.icon && <Box mr={1}>{option.icon}</Box>}
                  <Box>{option?.label}</Box>
                </Box>
              </MenuItem>
            ) : (
              <MenuItem
                style={{
                  
                  display: 'flex',
                  justifyContent: 'start',
                  padding: '10px'
                }}
                key={option}
                value={option}
              >
                <Box display="flex">
                  {option?.icon && <Box mr={1}>{option.icon}</Box>}
                  <Box>{option}</Box>
                </Box>
              </MenuItem>
            );
          })}
        </KenTextField>
        {error && (
          <Box width="16px" height="16px" margin="19px 8px">
            <KenIcon iconType="img" icon={ErrorIcon} variant="extraSmall"></KenIcon>
          </Box>
        )}
      </Box>
      {ui && ui?.divider && (
        <Box mt={3} mb={6}>
          <Divider />
        </Box>
      )}
    </>
  );
}
